import React, { useState, useEffect, useContext } from "react";
import Navbar from "../../components/navbar";
import "./privacy.css";
import MobileNavbar from "../../components/navbar/mobile";
import { BankContext } from "../../context/Context";

const Privacy = () => {
  const { isScrolled, setIsScrolled } = useContext(BankContext);

  useEffect(() => {
    const homeElement = document.querySelector(".privacy");

    const handleScroll = () => {
      const scrollPosition = homeElement.scrollTop;
      const containerHeight = homeElement.clientHeight;
      const scrollThreshold = containerHeight * 0.5; // 50% of the container height

      if (scrollPosition >= scrollThreshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    homeElement.addEventListener("scroll", handleScroll);

    return () => {
      homeElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="privacy">
      {window.innerWidth > 768 ? (
        <Navbar isScrolled={isScrolled} />
      ) : (
        <MobileNavbar />
      )}
      <div className="termsContainer">
        <h1>Cornerstone Website Privacy Policy</h1>
        <p>Effective Date: 12/09/2023</p>
        <p>
          This Privacy Policy outlines how Cornerstone
          (https://fmabenefits.com/) ("we," "our," or "us") collects, uses,
          discloses, and protects your personal information when you access and
          use our website (the "Website").
        </p>
        <h3>Information We Collect</h3>
        <p>
          We may collect the following types of personal information when you
          use our Website:
        </p>
        <ol style={{ paddingLeft: "2rem" }}>
          <li>
            <strong>Personal Information:</strong> This may include your name,
            email address, phone number, and other contact information when you
            provide it to us.
          </li>
          <li>
            <strong>Log Data:</strong> We may automatically collect information
            such as your IP address, browser type, operating system, and other
            technical details when you use our Website.
          </li>
          <li>
            <strong>Cookies:</strong> We may use cookies and similar tracking
            technologies to collect data about your interactions with our
            Website. You can manage your cookie preferences through your browser
            settings.
          </li>
        </ol>
        <h3>How We Use Your Information</h3>
        <p>We use your personal information for the following purposes:</p>
        <ol style={{ paddingLeft: "2rem" }}>
          <li>
            <strong>Insurance Quotations:</strong> We may use your information
            to get quotations from the insurance companies.
          </li>
          <li>
            <strong>Communication:</strong> We may use your contact information
            to respond to your inquiries, send important updates, and provide
            customer support.
          </li>
          <li>
            <strong>Improvement:</strong> We analyze data to enhance and
            optimize our Website's performance and user experience.
          </li>
          <li>
            <strong>Marketing:</strong> With your consent, we may send you
            promotional emails about our products and services. You can opt-out
            of these communications at any time.
          </li>
        </ol>
        <h3>Data Sharing</h3>
        <p>
          We do not sell, trade, or rent your personal information to third
          parties. However, we may share your information with trusted
          third-party service providers who assist us in operating our Website,
          conducting our business, or serving you, subject to confidentiality
          agreements.
        </p>
        <h3>Legal Requirements</h3>
        <p>
          We may disclose your personal information if required to do so by law
          or in response to a court order, or if such disclosure is necessary to
          protect our rights, your safety, or the safety of others.
        </p>
        <h3>Data Security</h3>
        <p>
          We implement reasonable security measures to protect your personal
          information from unauthorized access, disclosure, alteration, or
          destruction. However, no method of transmission over the internet is
          entirely secure, and we cannot guarantee absolute security.
        </p>
        <h3>Links to Third-Party Websites</h3>
        <p>
          Our Website may contain links to third-party websites or services. We
          are not responsible for their content, privacy practices, or how they
          collect and use your personal information. We recommend reviewing the
          privacy policies of those websites.
        </p>
        <h3>Children's Privacy</h3>
        <p>
          Our Website is not intended for children under the age of 13. We do
          not knowingly collect personal information from children. If you
          believe we have inadvertently collected information from a child,
          please contact us to remove the information.
        </p>
        <h3>Your Choices</h3>
        <p>
          You have the right to access, update, or delete your personal
          information. You can also opt-out of marketing communications at any
          time by following the unsubscribe instructions provided in our emails
          or contacting us directly.
        </p>
        <h3>Changes to this Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or for legal and regulatory reasons. We will post the
          revised Privacy Policy on our Website with the effective date. Please
          review it periodically.
        </p>
        <h3>Contact Us</h3>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at{" "}
          <a href="mailto:connect@rahainsure.com">connect@rahainsure.com</a>
        </p>
      </div>
    </div>
  );
};

export default Privacy;
