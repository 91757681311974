import React, { useState } from "react";
import classNames from "./basichome.module.scss";
import fullLogo from "../../assest/images/fullLogo.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BasicHome = () => {
  const history = useHistory();
  //constant
  const mainNavs = [
    { title: "Employers", link: "/employer/login" },
    { title: "Employees", link: "employee/login" },
    { title: "Cornerstone Employee", link: "" },
  ];

  const associateNavs = [
    { title: "Associates", link: "/associates/login" },
    { title: "Team Leads", link: "/teamlead/login" },
    { title: "Business Head", link: "/businesshead/login" },
    { title: "Vertical Heads", link: "/verticalhead/login" },
    { title: "Affiliate/Partner", link: "/affiliates/login" },
    { title: "Admin", link: "/admin/login" },
  ];

  const [currentNavs, setCurrentNavs] = useState(false);

  return (
    <div className={classNames.basicHome}>
      <img src={fullLogo} alt="fullLogo" />
      <div className={classNames.title}>Login as</div>
      <div className={classNames.mainNavigations}>
        {currentNavs
          ? associateNavs?.map((eachNav, index) => {
              return (
                <div
                  key={eachNav?.title}
                  onClick={() => {
                    if (eachNav?.link) {
                      history.push(eachNav?.link);
                    }
                  }}
                >
                  {eachNav?.title}
                </div>
              );
            })
          : mainNavs?.map((eachNav, index) => {
              return (
                <div
                  key={eachNav?.title}
                  onClick={() => {
                    if (eachNav?.title === "Cornerstone Employee") {
                      setCurrentNavs(true);
                    } else if (eachNav?.link) {
                      history.push(eachNav?.link);
                    }
                  }}
                >
                  {eachNav?.title}
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default BasicHome;
