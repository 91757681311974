import React, { useEffect, useState } from "react";
import classNames from "./newquote.module.scss";

//assets
import logoWithTagline from "../../assest/images/logos/logowithtagline.svg";
import rahabot from "../../assest/images/draft/rahabot.svg";
import whatsappIcon from "../../assest/images/draft/whatsapp.svg";
import messageIcon from "../../assest/images/draft/message.svg";
import {
  EachCustomDatePicker,
  EachCustomDropdown,
  EachCustomInput,
} from "../../components/Custom/Input";
import bgImgCompressed from "../../assest/images/login/sideImage.svg";
import Axios from "axios";
import { predefinedToast } from "../../utils/toast";
import fullLogo from "../../assest/images/fullLogo.svg";
import yesIcon from "../../assest/images/icons/yes.svg";

const NewQuote = () => {
  const [submitIsChecked, setSubmitIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [formDetails, setFormDetails] = useState({
    reference_email: "shorupandomains@gmail.com",
    external_lead: true,
  });

  //functions

  async function parseAddress(addressString) {
    const addressParts = addressString.split(", ");

    if (addressParts.length < 2) {
      // Handle invalid address format
      return null;
    }

    const city = addressParts[0];
    const statePostalCode = addressParts[1];

    const statePostalCodeParts = statePostalCode.split(" ");
    const state = statePostalCodeParts.slice(0, -1).join(" ");
    const postalCode = statePostalCodeParts.slice(-1)[0];

    const addressObject = {
      city: city,
      state: state,
      postalCode: postalCode,
    };

    return await addressObject;
  }

  function getPlaceFromPincodeEntry(pincode) {
    console.log(pincode, "pincode from function entry");
    Axios.get(
      `https://coverfi.apimachine.com/api/v1/google/geo-location/${pincode}`
    )
      .then(async (response) => {
        if (response?.data?.data?.length > 0) {
          let address = await parseAddress(
            response?.data?.data[0]?.formatted_address
          );

          setFormDetails((prev) => {
            return {
              ...prev,
              city: address?.city,
              state: address?.state,
            };
          });
        }
      })
      .catch((error) => {
        console.log(error?.message, "getPlaceFromPincode error");
      });
  }

  async function createExternalLead() {
    setIsLoading(true);
    const {
      reference_email,
      external_lead,
      first_name,
      last_name,
      company_name,
      company_size,
      lead_email,
      phone_number,
      pin_code,
      what_are_you_looking_for,
    } = formDetails;

    //passing object till complete all fields in form
    let obj = {
      reference_email,
      external_lead,
      first_name,
      last_name,
      company_name,
      company_size,
      lead_email,
      phone_number,
      pin_code,
      reference_email,
      what_are_you_looking_for,
    };
    console.log(obj, "formDetails createExternalLead");
    try {
      let response = await Axios.post(
        "https://insurancecoverfi.apimachine.com/insurance/lead/create",
        obj
      );
      if (response?.data?.status) {
        predefinedToast(response?.data?.message);
        setFormSuccess(true);
      } else {
        predefinedToast(
          response?.data?.message
            ? response?.data?.message
            : "Error while creating lead"
        );
      }
      setIsLoading(false);
      console.log(response, "createExternalLead response");
    } catch (error) {
      setIsLoading(false);
      console.log(error?.message, "Create external lead error");
    }
  }

  useEffect(() => {
    if (formDetails?.pin_code) {
      getPlaceFromPincodeEntry(formDetails?.pin_code);
    } else if (!formDetails?.pin_code) {
      console.log("Going inside empty pincode", formDetails?.pin_code);
      setFormDetails((prev) => {
        return {
          ...prev,
          city: "",
          state: "",
        };
      });
    }
  }, [formDetails?.pin_code]);

  return (
    <div className={classNames.newQuote}>
      <div className={classNames.loginNavbar}>
        <img
          src={logoWithTagline}
          alt="logoWithTagline"
          className={classNames.logo}
          onClick={() => {
            window.open("https://talltree.coverfi.app/");
          }}
        />
        <div
          className={classNames.backBtn}
          onClick={() => {
            window?.open("https://https://fmabenefits.com//", "_blank");
          }}
        >
          Back to Website
        </div>
      </div>
      <div className={classNames.mainGrid}>
        <div className={classNames.rightSide}>
          <div className={classNames.welcomeContainer}>
            <div className={classNames.desc}>
              Discover solutions tailored just for you. Share your details now.
            </div>
          </div>
          <img src={bgImgCompressed} alt="" />
          {/* <img src={bgImgCompressed} alt="" style={{ height: "95%" }} /> */}
        </div>
        <div className={classNames.formParent}>
          <div className={classNames.formContainer}>
            {isLoading && (
              <div className={classNames.loadingContainer}>
                <img src={fullLogo} alt="fullLogo" />
              </div>
            )}
            <div className={classNames.leftContainer}>
              {formSuccess ? (
                <div className={classNames.successMessage}>
                  <img src={yesIcon} alt="yesIcon" />
                  <div className={classNames.title}>
                    Thank You! Your Request Has <br />
                    Been Submitted
                  </div>
                  <div className={classNames.desc}>
                    Here Are Your Next Steps
                  </div>
                  <ol className={classNames.stepsList}>
                    <li>
                      You will have received temporary login credentials to{" "}
                      {formDetails?.lead_email}
                    </li>
                    <li>
                      <span>
                        Please use those to login to our{" "}
                        <span
                          onClick={() =>
                            window.open(
                              "https://talltree.coverfi.app/employer/login",
                              "_blank"
                            )
                          }
                        >
                          Employers App
                        </span>{" "}
                        and add the exact{" "}
                      </span>
                      <br />
                      <span>
                        coverage requirements you wish to obtain a quote for.{" "}
                      </span>
                    </li>
                  </ol>
                </div>
              ) : (
                <>
                  <div className={classNames.header}>
                    Tell us a bit about yourself.
                  </div>
                  <div className={classNames.form}>
                    <div className={classNames.threeFields}>
                      <EachCustomInput
                        placeholder="Enter Company Name"
                        name="company_name"
                        stateValue={formDetails}
                        setState={setFormDetails}
                        validation={{ type: "company_name_validation" }}
                      />
                      <EachCustomInput
                        placeholder="Enter First Name"
                        name="first_name"
                        stateValue={formDetails}
                        setState={setFormDetails}
                        validation={{ type: "name_validation" }}
                      />
                      <EachCustomInput
                        placeholder="Enter Last Name"
                        name="last_name"
                        stateValue={formDetails}
                        setState={setFormDetails}
                        validation={{ type: "name_validation" }}
                      />
                    </div>
                    <div className={classNames.twoFields}>
                      <EachCustomInput
                        placeholder="Role / Designation"
                        name="designation"
                        stateValue={formDetails}
                        setState={setFormDetails}
                      />
                      <EachCustomDropdown
                        placeholder="Select Your Department Name"
                        dropdown={[
                          "Human Resources",
                          "Finance",
                          "Procurements",
                          "Admin",
                        ]}
                        name="department"
                        stateValue={formDetails}
                        setState={setFormDetails}
                        typee="single"
                        asteriskIconn="true"
                        noopen="true"
                      />
                    </div>
                    <div className={classNames.twoFields}>
                      <EachCustomInput
                        placeholder="Enter Email ID"
                        name="lead_email"
                        stateValue={formDetails}
                        setState={setFormDetails}
                        validation={{ type: "email" }}
                      />
                      <EachCustomInput
                        placeholder="Enter Mobile Number"
                        name="phone_number"
                        stateValue={formDetails}
                        setState={setFormDetails}
                        validation={{ type: "phone_validation" }}
                      />
                      {/* <div className={classNames.mobileNumberFields}>
                        <div style={{ pointerEvents: "none" }}>
                          <EachCustomInput
                            placeholder="+91"
                            name="phone_number"
                            stateValue={{ phone_number: "+91" }}
                            setState={setFormDetails}
                          />
                        </div>
                        <EachCustomInput
                          placeholder="Enter Mobile Number"
                          name="phone_number"
                          stateValue={formDetails}
                          setState={setFormDetails}
                          validation={{ type: "phone_validation" }}
                        />
                      </div> */}
                    </div>

                    <div className={classNames.fourFields}>
                      <EachCustomDropdown
                        placeholder="Policy Type"
                        dropdown={[
                          { title: "Health", value: "Health" },
                          { title: "Accident", value: "Accidental" },
                          { title: "Life", value: "Life" },
                        ]}
                        name="what_are_you_looking_for"
                        stateValue={formDetails}
                        setState={setFormDetails}
                        typee="objVal"
                        objName="value"
                        mapName="value"
                      />
                      <EachCustomInput
                        placeholder="Total Employees"
                        name="company_size"
                        stateValue={formDetails}
                        setState={setFormDetails}
                        validation={{ type: "employee_size" }}
                      />
                      <EachCustomDropdown
                        placeholder="Fresh/Renewal"
                        dropdown={["Fresh", "Renewal"]}
                        name="department"
                        stateValue={formDetails}
                        setState={setFormDetails}
                        typee="single"
                        asteriskIconn="true"
                        noopen="true"
                      />
                      <div>
                        {formDetails?.department == "Fresh" ? (
                          <EachCustomDropdown
                            placeholder="Time to buy policy"
                            dropdown={[
                              "Within 30 days",
                              "Within 60 days",
                              "Within 3 months",
                              "Within 6 months",
                            ]}
                            name="policy_time"
                            stateValue={formDetails}
                            setState={setFormDetails}
                            typee="single"
                            asteriskIconn="true"
                            noopen="true"
                          />
                        ) : (
                          <EachCustomDatePicker
                            //   title="Policy valid until"
                            placeholder="Due date"
                            // dropdown={usersDropdown}
                            name="due_date"
                            stateValue={formDetails}
                            setState={setFormDetails}
                            //   mandatory={clientOnboardingForm ? true : false}
                            typee="spreadObj"
                          />
                        )}
                      </div>
                    </div>
                    <div className={classNames.twoFields}>
                      <EachCustomDropdown
                        placeholder="Select Family Definition"
                        dropdown={[
                          "Employee Only",
                          "Employee plus Partner & one Child",
                          "Employee plus Partner, 2 Children & Parents / Parents-in-Laws",
                          "Employee plus Partner, 2 Children, Parents & Parents-in-Laws",
                        ]}
                        name="family_definition"
                        stateValue={formDetails}
                        setState={setFormDetails}
                        typee="single"
                        asteriskIconn="true"
                        noopen="true"
                      />
                      <div className={classNames.threeFields}>
                        <EachCustomInput
                          placeholder="Pin code"
                          name="pin_code"
                          stateValue={formDetails}
                          setState={setFormDetails}
                          validation={{ type: "pincode_validation" }}
                        />
                        <EachCustomInput
                          placeholder="City"
                          name="city"
                          stateValue={formDetails}
                          setState={setFormDetails}
                        />
                        <EachCustomInput
                          placeholder="State"
                          name="state"
                          stateValue={formDetails}
                          setState={setFormDetails}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classNames.checkBox}>
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      checked={submitIsChecked}
                      onChange={() => setSubmitIsChecked(!submitIsChecked)}
                    />
                    <div>
                      I prefer to receive the call within 24 hours, please.
                    </div>
                  </div>
                  <div
                    className={classNames.submitBtn}
                    style={{
                      pointerEvents: submitIsChecked ? "" : "none",
                      opacity: submitIsChecked ? "" : "0.5",
                    }}
                    onClick={createExternalLead}
                  >
                    Submit
                  </div>
                </>
              )}
            </div>
            <div className={classNames.rightContainer}>
              <div className={classNames.topDivider}>
                <img src={rahabot} alt="rahabot" />
              </div>
              <div className={classNames.bottomContent}>
                <div className={classNames.name}>Hi, I'm The RahaBot</div>
                <div className={classNames.desc}>
                  Feel free to reach out if you have any questions about your
                  Cornerstone journey.
                </div>
                <div className={classNames.btnsContainer}>
                  <div
                    onClick={() =>
                      window.open("https://wa.me/9100098491", "_blank")
                    }
                  >
                    <img src={messageIcon} alt="messageIcon" />
                    Live Chat
                  </div>
                  <div
                    onClick={() =>
                      window.open("https://wa.me/9100098491", "_blank")
                    }
                  >
                    <img src={whatsappIcon} alt="whatsappIcon" />
                    <span>WhatsApp</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.loginFooter}>
        <div>© Cornerstone Fintech Pvt. Ltd. All Rights Reserved 2024.</div>
        <div>
          Insurance products are offered and serviced by Cornerstone Insurance
          Broking Services Pvt Ltd | IRDAI Certificate No. 321, License category
          - Direct Broker (Life & General), Valid till 05-01-2027 | CIN
          U74140TG2003PTC040308
        </div>
      </div>
    </div>
  );
};

export default NewQuote;
