import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./dashboard.module.scss";

import moment from "moment";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import Axios from "axios";
import { BankContext } from "../../../context/Context";
import CustomCheckbox, {
  CopyBtn,
  LoadingAnimationRahaLogo,
} from "../../../components/Custom";
import Skeleton from "react-loading-skeleton";
import { Redirect } from "react-router-dom";
import NavbarV2 from "../../../components/NavbarV2";

import { ReactComponent as FilterIcon } from "../../../assest/images/allicons/filter.svg";
import { ReactComponent as SortArrows } from "../../../assest/images/allicons/sort.svg";

//action buttons icons
import { ReactComponent as EditIcon } from "../../../assest/images/allicons/tableaction/edit.svg";
import { ReactComponent as CopyIcon } from "../../../assest/images/allicons/tableaction/copy.svg";
import { ReactComponent as WebIcon } from "../../../assest/images/allicons/tableaction/web.svg";
import { ReactComponent as InitiateQuoteIcon } from "../../../assest/images/allicons/tableaction/initiateQuote.svg";
import { ReactComponent as DeleteIcon } from "../../../assest/images/allicons/tableaction/delete.svg";
import { ReactComponent as DownloadIcon } from "../../../assest/images/allicons/tableaction/download.svg";
import { ReactComponent as EmailIcon } from "../../../assest/images/allicons/tableaction/email.svg";
import { ReactComponent as BandaidIcon } from "../../../assest/images/allicons/tableaction/bandaid.svg";
import { ReactComponent as LockIcon } from "../../../assest/images/allicons/tableaction/lock.svg";
import { ReactComponent as DependentsIcon } from "../../../assest/images/allicons/tableaction/dependents.svg";
import { ReactComponent as AddIcon } from "../../../assest/images/allicons/tableaction/add.svg";
import { IoClose } from "react-icons/io5";
import { ReactComponent as TrashIcon } from "../../../assest/images/allicons/tableaction/trash.svg";
import { ReactComponent as PeoplesIcon } from "../../../assest/images/allicons/tableaction/peoples.svg";
import { ReactComponent as PeopleAddIcon } from "../../../assest/images/allicons/tableaction/peopleadd.svg";
import { ReactComponent as PeopleAddRemoveIcon } from "../../../assest/images/allicons/tableaction/peopleaddremove.svg";
import { ReactComponent as KeyIcon } from "../../../assest/images/allicons/tableaction/key.svg";
import { ReactComponent as SingleAddIcon } from "../../../assest/images/allicons/tableaction/singleadd.svg";
import { ReactComponent as BulkAddIcon } from "../../../assest/images/allicons/tableaction/bulkadd.svg";
import { ReactComponent as ReregisterIcon } from "../../../assest/images/allicons/tableaction/reregister.svg";
import { useHistory } from "react-router-dom";
import { dashboardMainScore } from "../../../assest/data/associates/dashboard";
import { ReactComponent as ColorLogo } from "./colorLogo.svg";
import {
  MdKeyboardDoubleArrowUp,
  MdKeyboardDoubleArrowDown,
} from "react-icons/md";
import { IoIosTrendingUp } from "react-icons/io";

import { ReactComponent as ContactIcon } from "../../../assest/images/associates/dashboard/contact.svg";
import { ReactComponent as PoepleIcon } from "../../../assest/images/associates/dashboard/people.svg";
import { ReactComponent as BoxIcon } from "../../../assest/images/associates/dashboard/box.svg";
import { ReactComponent as ComputerIcon } from "../../../assest/images/associates/dashboard/computer.svg";

import { BsCheck, BsThreeDotsVertical } from "react-icons/bs";
import { IoMdClose, IoMdSearch } from "react-icons/io";
import donutGraph from "./dummygraph/donut.svg";
import barGraph from "./dummygraph/bar.svg";
import graph1 from "./dummygraph/graph.svg";
import graph2 from "./dummygraph/graph2.svg";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { getData } from "../../../APIs";
import NavbarV3 from "../../../components/NavbarV3";
import InitiateQuote, {
  AddRahaEmployee,
  CompleteRegistration,
} from "./InitialteQuote";
import AddPolicy, { AddNewRaterCard } from "./AddPolicy";
import AssignRole from "./AssignRole";
import ClientDetails from "./ClientDetails";
import PolicyDetails from "./PolicyDetails";
import { getCurrentDate, logoutToSameLogin } from "../../../assest/functions";
import { predefinedToast } from "../../../utils/toast";
import { ReactComponent as adminResetIcon } from "../../../assest/images/allicons/tableaction/adminResetIcon.svg";
import AdminResetPassword from "../../../components/AssociatesCommonComponents/AdminResetPassword";

ChartJS.register(ArcElement, Tooltip, Legend);

const DashboardAssociate = () => {
  const history = useHistory();
  const {
    email,
    popularAction,
    setPopularAction,
    actionBtns,
    setActionBtns,
    globalHrId,
    setUpdateLoading,
    globalSelectedPolicy,
    addDependentSidebar,
    setAddDependentSidebar,
    lastSelectedEmployee,
    setLastSelectedEmployee,
  } = useContext(BankContext);

  const [selectedProfileNav, setSelectedProfileNav] = useState("Dashboard");

  const [filterOpen, setFilterOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [usersAllCompany, setUsersAllCompany] = useState("");
  const [usersAllCompanySelected, setUsersAllCompanySelected] = useState("");

  const [usersAllStates, setUsersAllStates] = useState("");
  const [usersAllStatesSelected, setUsersAllStatesSelected] = useState("");
  const [toggleSelectedTable, setToggleSelectedTable] = useState("Simplified");

  const [allBrokeragesSearch, setAllBrokeragesSearch] = useState("");
  const [allLeads, setAllLeads] = useState("");
  const [allLeadsAPI, setAllLeadsAPI] = useState("");
  const [allLeadsLoading, setAllLeadsLoading] = useState("");
  const [allBrokeragesTotalProfile, setAllBrokeragesTotalProfile] =
    useState("");

  //imported from store
  const [allSelectedPolicyClaims, setSelectedPolicyClaims] = useState([]);
  const [clientStats, setClientStats] = useState("");
  const [allSelectedPolicyClaimsLoading, setSelectedPolicyClaimsLoading] =
    useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [queriedFilter, setQueriedFilter] = useState("beneficiarY_NAME");

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [topStat, setTopStat] = useState(null);

  const filterDropdownRef = useRef(null);

  const [popup, setPopup] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showLeadInfo, setShowLeadInfo] = useState(false);
  const [userData, setUserData] = useState(null);
  const [copyLoading, setCopyLoading] = useState(null);
  const [allAssociateLeads, setAllAssociateLeads] = useState([]);

  const [showAssignPopup, setShowAssignPopup] = useState(null);
  const [selectedTab, setSelectedTab] = useState("All");
  const [localRefresh, setLocalRefresh] = useState(false);

  useEffect(() => {
    if (copyLoading) {
      setTimeout(() => {
        setCopyLoading(null);
      }, 1000);
    }
  }, [copyLoading]);

  useEffect(() => {
    allClientsTeamLead();
    clientsStatsTeamLead();

    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setFilterOpen(false);
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  async function allClientsTeamLead() {
    setSelectedPolicyClaimsLoading(true);
    if (
      localStorage.getItem("loggedInUserRoleId") &&
      localStorage.getItem("loggedInUserId")
    ) {
      Axios.get(
        `https://coverfi.apimachine.com/api/v1/raha-lead/mapping/project/?role_id=${localStorage.getItem(
          "loggedInUserRoleId"
        )}&user_id=${localStorage.getItem("loggedInUserId")}`
        // `https://insurancecoverfi.apimachine.com/insurance/lead/get/projected?reference_email=${email}&lead_type=company`,
      ).then(({ data }) => {
        if (data.status) {
          setSelectedPolicyClaims(data.data);
          setSelectedPolicyClaimsLoading(false);
        }
      });
    }
  }
  const [teamTableData, setTeamTableData] = useState([]);
  const [teamTableDataLoading, setTeamTableDataLoading] = useState(false);
  const getTeamTableData = () => {
    setTeamTableDataLoading(true);
    Axios.get(`https://coverfi.apimachine.com/api/v1/raha-user`).then(
      ({ data }) => {
        if (data.status) {
          setTeamTableData(data.data);
          setTeamTableDataLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    if (selectedProfileNav === "Dashboard") {
      allClientsTeamLead();
    } else if (selectedProfileNav === "Team") {
      getTeamTableData();
    }
  }, [
    localStorage.getItem("loggedInUserRoleId"),
    localStorage.getItem("loggedInUserId"),
    selectedProfileNav,
    localRefresh,
  ]);

  async function clientsStatsTeamLead() {
    let response = await getData(
      `https://insurancecoverfi.apimachine.com/insurance/lead/get/hierarchy/count?team_lead=${email}`
    );
    setClientStats(response);
    console.log(response, "clients stats");
  }

  const getTopStat = () => {
    if (
      localStorage.getItem("loggedInUserRoleId") &&
      localStorage.getItem("loggedInUserId")
    ) {
      Axios.get(
        `https://coverfi.apimachine.com/api/v1/raha-lead/mapping/statistics/?role_id=${localStorage.getItem(
          "loggedInUserRoleId"
        )}&user_id=${localStorage.getItem("loggedInUserId")}`
      ).then(({ data }) => {
        if (data.status) {
          console.log(data.data[0], "kwefkwefkwekfw");
          setTopStat(data.data[0]);
        }
      });
    }
  };

  const getAllLeads = () => {
    Axios.get(
      `https://coverfi.apimachine.com/api/v1/raha-lead/mapping/notmapped`
    ).then(({ data }) => {
      setAllAssociateLeads(data.data);
    });
  };
  const getAllLeadsStatus = () => {
    let status;
    if (selectedTab === "Active") {
      status = "Completed";
    } else if (selectedTab === "Inactive") {
      status = "No-Quotes";
    } else if (selectedTab === "In Process") {
      status = "In-Process";
    }

    if (status) {
      Axios.get(
        `https://coverfi.apimachine.com/api/v1/raha-lead/mapping/notmapped?quote_status=${status}`
      ).then(({ data }) => {
        console.log(data?.data, "All clients data agency dashboard");
        setAllAssociateLeads(data.data);
      });
    }
  };

  useEffect(() => {
    if (showAllLeadData) {
      const found = allAssociateLeads.find(
        (o) => o.lead_id === showAllLeadData?.lead_id
      );
      setShowAllLeadData(found);
    }
  }, [allAssociateLeads]);

  const [teamTopStat, setTeamTopStat] = useState([]);
  const getTeamTopStat = () => {
    Axios.get(
      `https://coverfi.apimachine.com/api/v1/raha-user/role/stats`
    ).then(({ data }) => {
      if (data.status) {
        setTeamTopStat([
          {
            title: "Associates",
            icon: <ContactIcon />,
            score: data.data.find((o) => o.role_designation === "Associates")
              ?.user_role_count,
          },
          {
            title: "Team Leads",
            icon: <PoepleIcon />,
            score: data.data.find((o) => o.role_designation === "Team Leader")
              ?.user_role_count,
          },
          {
            title: "Vertical Heads",
            icon: <BoxIcon />,
            score: data.data.find((o) => o.role_designation === "Vertical Head")
              ?.user_role_count,
          },
          {
            title: "Business Heads",
            icon: <ComputerIcon />,
            score: data.data.find((o) => o.role_designation === "Business Head")
              ?.user_role_count,
          },
        ]);
      }
    });
  };

  useEffect(() => {
    getTeamTopStat();
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("loggedInUserRoleId") &&
      localStorage.getItem("loggedInUserId")
    ) {
      getTopStat();
    }
  }, [
    localStorage.getItem("loggedInUserRoleId"),
    localStorage.getItem("loggedInUserId"),
  ]);

  const [selectedTab1, setSelectedTab1] = useState("");
  const [selectedTabYearly, setSelectedTabYearly] = useState("");
  const [selectedTabWeekly, setSelectedTabWeekly] = useState("");
  const [selectedTabMonthly, setSelectedTabMonthly] = useState("");
  const [selectedTabCity, setSelectedTabCity] = useState("");
  const [detailedOverview, setDetailedOverview] = useState(false);
  const selectedItemTable = useRef(null);

  const [selectedMenu, setSelectedMenu] = useState(null);

  const [showDeleteConf, setShowDeleteConf] = useState(null);

  const [userCdBal, setUserCdBal] = useState(null);
  const [showInitiateSuccess, setShowInitiateSuccess] = useState(false);
  const [allExistingRoleForUser, setAllExistingRoleForUser] = useState([]);
  const [allPossibleRoles, setAllPossibleRoles] = useState([]);

  const getUserInfo = () => {
    setUserData(null);
    setUserCdBal(null);
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/user/getuserallprofile?user_email=${selectedRow?.lead_email}`
    ).then(({ data }) => {
      console.log(data, selectedRow?.lead_email, "kjwefkjwgekfjgwekf");
      if (data.status) {
        setUserData(data.data);
      }
    });
  };

  const getCdbal = () => {
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/cdBalance/hr/get?hr_id=${selectedRow?.hr_id}`
    ).then(({ data }) => {
      if (data.status) {
        setUserCdBal(data.data[0]?.cdBalance_id);
      }
    });
  };

  const activateCDBalance = () => {
    Axios.post(
      `https://insurancecoverfi.apimachine.com/insurance/cdBalance/hr/create`,
      {
        hr_id: selectedRow?.hr_id,
      }
    ).then(({ data }) => {
      if (data.status) {
        setSelectedMenu(null);
        setSelectedRow(null);
        setActionBtns([]);
      }
    });
  };

  const makeLeadActive = async () => {
    try {
      let response = await Axios.put(
        `https://insurancecoverfi.apimachine.com/insurance/lead/update?lead_id=${selectedRow?.lead_id}`,
        {
          quote_status: "Completed",
        }
      );
      if (response?.data?.status) {
        predefinedToast(response?.data?.message);
      } else {
        predefinedToast(response?.data?.message);
      }
      allClientsTeamLead();
      setSelectedMenu(null);
      setSelectedRow(null);
      setActionBtns([]);
      console.log(response, "Make lead active response");
    } catch (error) {
      predefinedToast("Error while making lead to active");
      console.log(error?.message, "Make lead active error");
    }
  };

  useEffect(() => {
    if (
      (selectedMenu === "See Client Details" && selectedRow) ||
      (selectedMenu === "See lead details" && selectedRow)
    ) {
      getUserInfo();
      getCdbal();
    } else if (selectedMenu === "Activate CD Balance") {
      activateCDBalance();
    } else if (selectedMenu === "Make lead active" && selectedRow?.lead_id) {
      makeLeadActive();
    } else if (selectedMenu === "Remove role") {
      getAllExistingRoleUser();
    } else if (selectedMenu === "Add role") {
      getAllPossibleRoles();
    }
  }, [selectedMenu, selectedRow]);

  async function getAllExistingRoleUser() {
    try {
      let response = await Axios.get(
        `https://coverfi.apimachine.com/api/v1/raha-user/?user_email_id=${selectedRow?.user_email_id}`
      );
      if (response?.data?.status && response?.data?.data?.length > 0) {
        console.log(response, "getAllExistingRoleUser response");
        setAllExistingRoleForUser(response?.data?.data[0].user_roles);
      } else {
        setAllExistingRoleForUser("");
      }
    } catch (error) {
      console.log(error?.message, "getAllExistingRoleUser error");
    }
  }

  async function getAllPossibleRoles() {
    try {
      let response = await Axios.get(
        `https://coverfi.apimachine.com/api/v1/raha-user/role`
      );
      if (response?.data?.status && response?.data?.data?.length > 0) {
        console.log(response, "getAllPossibleRoles response");
        setAllPossibleRoles(response?.data?.data);
      } else {
        setAllPossibleRoles("");
      }
    } catch (error) {
      console.log(error?.message, "getAllPossibleRoles error");
    }
  }

  async function allClientsTeamLeadStatus() {
    let status;
    if (selectedTab === "Active") {
      status = "Completed";
    } else if (selectedTab === "Inactive") {
      status = "No-Quotes";
    } else if (selectedTab === "In Process") {
      status = "In-Process";
    }
    if (status) {
      Axios.get(
        `https://coverfi.apimachine.com/api/v1/raha-lead/mapping/project/?role_id=${localStorage.getItem(
          "loggedInUserRoleId"
        )}&user_id=${localStorage.getItem(
          "loggedInUserId"
        )}&quote_status=${status}`
      ).then(({ data }) => {
        if (data.status) {
          setSelectedPolicyClaims(data.data);
        } else {
          setSelectedPolicyClaims([]);
        }
      });
    }
  }

  const handleDelete = (item) => {
    Axios.delete(
      `https://insurancecoverfi.apimachine.com/insurance/lead/delete?lead_id=${item?.lead_id}`
    ).then(({ data }) => {
      if (data.status) {
        allClientsTeamLead();
      }
    });
  };

  const initiateLead = (item) => {
    console.log(item, "lkehflkwehflkwhelfkw");
    Axios.post(
      `https://coverfi.apimachine.com/api/v1/raha-lead/mapping/bulk/create`,
      {
        lead_id: item?._id,
      }
    ).then(({ data }) => {
      if (data.status) {
        setShowInitiateSuccess(true);
        getAllLeads();
      }
    });
  };

  const conditionalClientData = (item, roleDesignation, roleType) => {
    if (
      Array.isArray(item?.lead_hierarchy_mapping) &&
      item?.lead_hierarchy_mapping?.length > 0
    ) {
      const found = item?.lead_hierarchy_mapping?.find(
        (o) =>
          o.role_id.role_designation === roleDesignation &&
          o.role_id.role_type === roleType &&
          o.user_id
      );
      const found1 = item?.lead_hierarchy_mapping?.find(
        (o) =>
          o.role_id.role_designation === roleDesignation &&
          o.role_id.role_type === roleType
      );
      if (found) {
        return (
          <div style={{ width: "100%" }}>
            <div
              className={classNames.valueTitle}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>{found?.user_id?.user_first_name}</div>
              <div
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={(e) => {
                  setShowAssignPopup({
                    fullData: item,
                    foundData: found1,
                  });
                }}
              >
                Change
              </div>
            </div>
            <div className={classNames.valueSubTitle}>
              User ID: {found?.user_id?._id}
            </div>
            <div className={classNames.valueSubTitle}>
              Role ID: {found?.role_id?._id}
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className={classNames.valueTitle}>Not Assigned</div>
            <div
              className={classNames.assignNow}
              onClick={(e) => {
                setShowAssignPopup({
                  fullData: item,
                  foundData: found1,
                });
              }}
            >
              Assign Now
            </div>
          </div>
        );
      }
    } else {
      return (
        <div>
          <div className={classNames.valueTitle}>Not Assigned</div>
          <div className={classNames.assignNow}>Assign Now</div>
        </div>
      );
    }
  };

  const [isToggled, setIsToggled] = useState(false);
  const [showAllLeadData, setShowAllLeadData] = useState(null);

  useEffect(() => {
    if (selectedTab === "All") {
      allClientsTeamLead();
    } else {
      allClientsTeamLeadStatus();
    }
  }, [
    selectedTab,
    localStorage.getItem("loggedInUserRoleId"),
    localStorage.getItem("loggedInUserId"),
  ]);

  useEffect(() => {
    if (selectedTab === "All") {
      getAllLeads();
    } else {
      getAllLeadsStatus();
    }
  }, [selectedTab, isToggled]);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const filterItemsDashboard = [
    {
      title: "See Client Details",
      icon: WebIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "Add new policy",
      icon: AddIcon,
      clickable: true,
      subMenu: "",
    },
    // {
    //   title: "Activate CD Balance",
    //   // icon: EditIcon,
    //   icon: AddIcon,
    //   clickable: true,
    //   subMenu: "",
    // },
    {
      title: "Download Health Cards",
      icon: DownloadIcon,
      clickable: true,
    },
    // {
    //   title: "Email Health Cards",
    //   icon: EmailIcon,
    //   clickable: true,
    //   subMenu: "",
    // },
    {
      title: "Initiate Endorsements",
      icon: PeopleAddRemoveIcon,
      clickable: true,
      // subMenu: [
      //   {
      //     title: "Single Entry",
      //     icon: "",
      //     clickable: true,
      //     subMenu: "",
      //     // link: "/app/coverfi/claims",
      //   },
      //   {
      //     title: "Bulk Upload",
      //     icon: "",
      //     clickable: true,
      //     subMenu: "",
      //     // link: "/app/coverfi/claims",
      //   },
      //   {
      //     title: "Re-Register",
      //     icon: "",
      //     clickable: true,
      //     subMenu: "",
      //     // link: "/app/coverfi/claims",
      //   },
      // ],
    },
    // {
    //   title: "Initiate A Claim",
    //   icon: BandaidIcon,
    //   clickable: true,
    //   subMenu: [
    //     {
    //       title: "Group Health Insurance",
    //       icon: "",
    //       clickable: true,
    //       subMenu: "",
    //       // link: "/app/coverfi/claims",
    //     },
    //     {
    //       title: "Group Personal Accident Insurance",
    //       icon: "",
    //       clickable: true,
    //       subMenu: "",
    //       // link: "/app/coverfi/claims",
    //     },
    //     {
    //       title: "Group Term Life Insurance",
    //       icon: "",
    //       clickable: true,
    //       subMenu: "",
    //       // link: "/app/coverfi/claims",
    //     },
    //   ],
    // },
    {
      title: "See policies",
      icon: WebIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "Admin reset password",
      icon: adminResetIcon,
      clickable: true,
      subMenu: "",
    },
    // {
    //   title: "Enable/Disable Account",
    //   icon: LockIcon,
    //   clickable: true,
    //   subMenu: "",
    // },
    // {
    //   title: "Reset Password",
    //   icon: KeyIcon,
    //   clickable: true,
    //   subMenu: "",
    // },
    // {
    //   title: "Initiate Pre-Enrollment",
    //   icon: PeoplesIcon,
    //   clickable: true,
    //   subMenu: "",
    // },
    // {
    //   title: "Single Entry/Bulk Upload",
    //   icon: PeopleAddIcon,
    //   clickable: true,
    //   subMenu: [
    //     {
    //       title: "Single Entry",
    //       icon: SingleAddIcon,
    //       clickable: false,
    //       subMenu: "",
    //       // link: "/app/coverfi/claims",
    //     },
    //     {
    //       title: "Bulk Upload",
    //       icon: BulkAddIcon,
    //       clickable: false,
    //       subMenu: "",
    //       // link: "/app/coverfi/claims",
    //     },
    //     {
    //       title: "Re-Register",
    //       icon: ReregisterIcon,
    //       clickable: false,
    //       subMenu: "",
    //       // link: "/app/coverfi/claims",
    //     },
    //   ],
    // },
  ];
  const inProcessMenu = [
    {
      title: "See lead details",
      icon: WebIcon,
      clickable: true,
      subMenu: "",
    },
    // {
    //   title: "Edit Lead Details",
    //   icon: EditIcon,
    //   clickable: true,
    //   subMenu: "",
    // },
    {
      title: "Initiate Quote",
      icon: InitiateQuoteIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "Admin reset password",
      icon: adminResetIcon,
      clickable: true,
      subMenu: "",
    },
    // {
    //   title: "Delete Lead",
    //   icon: DeleteIcon,
    //   clickable: true,
    //   subMenu: "",
    // },
  ];
  const inactiveMenu = [
    {
      title: "See lead details",
      icon: WebIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "Complete registration",
      icon: EditIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "Initiate Quote",
      icon: InitiateQuoteIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "Make lead active",
      icon: InitiateQuoteIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "Admin reset password",
      icon: adminResetIcon,
      clickable: true,
      subMenu: "",
    },
  ];
  const teamMembersMain = [
    {
      title: "See details",
      icon: WebIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "Add role",
      icon: AddIcon,
      clickable: true,
      subMenu: "",
    },
    {
      title: "Remove role",
      icon: IoClose,
      clickable: true,
      subMenu: "",
    },
  ];

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div
      className={classNames.profile}
      onClick={(event) => {
        if (
          addDependentSidebar &&
          !event.target.closest("#addDependentSidebar")
        ) {
          console.log(event.target, ": clicked id");
          setAddDependentSidebar(false);
        }
      }}
    >
      <NavbarV3
        dropdownItems={[
          { title: "Dashboard", clickable: true },
          { title: "Team", clickable: true },
        ]}
        selectedTab={selectedProfileNav}
        setSelectedTab={setSelectedProfileNav}
        setSearchQuery={setAllBrokeragesSearch}
        typee="v3"
        removeItem={["Policy"]}
      />
      <div className={classNames.brokerageDetails}>
        {selectedProfileNav === "Team" && (
          <div className={classNames.overallScore}>
            {teamTopStat?.map((eachItem, index) => {
              return (
                <div
                  className={classNames.eachScore}
                  key={eachItem?.title + index}
                >
                  <div className={classNames.scoreIconDiv}>
                    {eachItem?.icon}
                  </div>
                  <div
                    className={classNames.descDiv}
                    style={{ paddingTop: "15px" }}
                  >
                    <div
                      className={classNames.title}
                      style={{ fontSize: "14px", fontWeight: 700 }}
                    >
                      {eachItem?.title}
                    </div>
                    <div
                      className={classNames.score}
                      style={{ fontSize: "28px", fontWeight: 600 }}
                    >
                      {eachItem?.score}
                      {/* {teamTopStat ? (
                        eachItem?.title === "Associates" ? (
                          teamTopStat.find(
                            (o) => o.role_designation === "Associates"
                          )?.user_role_count
                        ) : eachItem?.title === "Team Leads" ? (
                          teamTopStat.find(
                            (o) => o.role_designation === "Team Leads"
                          )?.user_role_count
                        ) : eachItem?.title === "Vertical Heads" ? (
                          teamTopStat.find(
                            (o) => o.role_designation === "Vertical Heads"
                          )?.user_role_count
                        ) : eachItem?.title === "Business Heads" ? (
                          teamTopStat.find(
                            (o) => o.role_designation === "Business Heads"
                          )?.user_role_count
                        ) : (
                          0
                        )
                      ) : (
                        <Skeleton height={15} width={80} />
                      )} */}
                    </div>
                    {/* <div className={classNames.growthDiv}>
                    <span>
                      <IoIosTrendingUp />
                      16%
                    </span>
                    {eachItem?.growthPerc}
                  </div> */}
                  </div>
                </div>
              );
            })}
            {detailedOverview ? (
              <MdKeyboardDoubleArrowUp
                className={classNames.detailedOverview}
                onClick={() => {
                  setDetailedOverview((prev) => !prev);
                }}
              />
            ) : (
              <MdKeyboardDoubleArrowDown
                className={classNames.detailedOverview}
                style={{ pointerEvents: "none" }}
                onClick={() => {
                  setDetailedOverview((prev) => !prev);
                }}
              />
            )}
          </div>
        )}
        {selectedProfileNav !== "Team" && (
          <div className={classNames.overallScore}>
            {dashboardMainScore?.map((eachItem, index) => {
              return (
                <div
                  className={classNames.eachScore}
                  key={eachItem?.title + index}
                >
                  <div className={classNames.scoreIconDiv}>
                    {eachItem?.icon}
                  </div>
                  <div
                    className={classNames.descDiv}
                    style={{ paddingTop: "15px" }}
                  >
                    <div
                      className={classNames.title}
                      style={{ fontSize: "14px", fontWeight: 700 }}
                    >
                      {eachItem?.title}
                    </div>
                    <div
                      className={classNames.score}
                      style={{ fontSize: "28px", fontWeight: 600 }}
                    >
                      {topStat ? (
                        eachItem?.title === "Total Clients" ? (
                          topStat?.totalHrCount
                        ) : eachItem?.title === "Total Members" ? (
                          topStat?.total_lives
                        ) : eachItem?.title === "Total Claims" ? (
                          topStat?.total_claims ? (
                            topStat?.total_claims
                          ) : (
                            0
                          )
                        ) : eachItem?.title === "Active Leads" ? (
                          topStat?.totalLeadCount
                        ) : (
                          0
                        )
                      ) : (
                        <Skeleton height={15} width={80} />
                      )}
                    </div>
                    {/* <div className={classNames.growthDiv}>
                    <span>
                      <IoIosTrendingUp />
                      16%
                    </span>
                    {eachItem?.growthPerc}
                  </div> */}
                  </div>
                </div>
              );
            })}
            {detailedOverview ? (
              <MdKeyboardDoubleArrowUp
                className={classNames.detailedOverview}
                onClick={() => {
                  setDetailedOverview((prev) => !prev);
                }}
              />
            ) : (
              <MdKeyboardDoubleArrowDown
                className={classNames.detailedOverview}
                style={{ pointerEvents: "none" }}
                onClick={() => {
                  setDetailedOverview((prev) => !prev);
                }}
              />
            )}
          </div>
        )}
        {detailedOverview && (
          <div className={classNames.tableContainer} style={{ height: "90%" }}>
            <div className={classNames.graphBoxes}>
              <div>
                <div className={classNames.headerDiv}>
                  <div className={classNames.title}>Policy Engagement</div>
                  <div className={classNames.otherOptions}>
                    <CustomDropdown
                      buttonText={
                        selectedTabMonthly
                          ? selectedTabMonthly
                          : "Last 12 months"
                      }
                      // color="var(--main-color)"
                      dropdownItems={[
                        { title: "Last 30 days", clickable: true },
                        { title: "Last 60 days", clickable: true },
                        { title: "Last 90 days", clickable: true },
                        { title: "Last 6 months", clickable: true },
                        { title: "Last 12 months", clickable: true },
                      ]}
                      stateValue={selectedTabMonthly}
                      setState={setSelectedTabMonthly}
                      typee={"v3"}
                    />
                    <BsThreeDotsVertical />
                  </div>
                </div>
                <div className={classNames.canvasDiv}>
                  <img src={donutGraph} alt="donutGraph" />
                </div>

                {/* <Doughnut data={donutData(30, 50, 20)} options={donutOptions} /> */}
              </div>
              <div>
                <div className={classNames.headerDiv}>
                  <div className={classNames.title}>Lives By Demography</div>
                  <div className={classNames.otherOptions}>
                    <CustomDropdown
                      buttonText={selectedTabCity ? selectedTabCity : "By City"}
                      // color="var(--main-color)"
                      dropdownItems={[]}
                      stateValue={selectedTabCity}
                      setState={setSelectedTabCity}
                      typee={"v3"}
                    />
                    <BsThreeDotsVertical />
                  </div>
                </div>
                <div className={classNames.canvasDiv}>
                  <img src={barGraph} alt="barGraph" />
                </div>
              </div>
              <div>
                <div className={classNames.headerDiv}>
                  <div className={classNames.title}>Claims History</div>
                  <div className={classNames.otherOptions}>
                    <div className={classNames.lables}>
                      <span style={{ background: "#4745A4" }}></span>
                      Claimed
                    </div>
                    <div className={classNames.lables}>
                      <span style={{ background: "#F9BA33" }}></span>
                      Settled
                    </div>
                    <CustomDropdown
                      buttonText={
                        selectedTabWeekly ? selectedTabWeekly : "Last Week"
                      }
                      // color="var(--main-color)"
                      dropdownItems={[
                        { title: "Last 30 days", clickable: true },
                        { title: "Last 60 days", clickable: true },
                        { title: "Last 90 days", clickable: true },
                        { title: "Last 6 months", clickable: true },
                        { title: "Last 12 months", clickable: true },
                      ]}
                      stateValue={selectedTabWeekly}
                      setState={setSelectedTabWeekly}
                      typee={"v3"}
                    />
                    <BsThreeDotsVertical />
                  </div>
                </div>
                <div className={classNames.canvasDiv}>
                  <img src={graph1} alt="graph1" />
                </div>
              </div>
              <div>
                <div className={classNames.headerDiv}>
                  <div className={classNames.title}>Premium Overview</div>
                  <div className={classNames.otherOptions}>
                    <CustomDropdown
                      buttonText={
                        selectedTabYearly ? selectedTabYearly : "Yearly"
                      }
                      // color="var(--main-color)"
                      dropdownItems={[
                        { title: "Last 30 days", clickable: true },
                        { title: "Last 60 days", clickable: true },
                        { title: "Last 90 days", clickable: true },
                        { title: "Last 6 months", clickable: true },
                        { title: "Last 12 months", clickable: true },
                      ]}
                      stateValue={selectedTabYearly}
                      setState={setSelectedTabYearly}
                      typee={"v3"}
                    />
                  </div>
                </div>
                <div className={classNames.canvasDiv}>
                  <img src={graph2} alt="graph2" />
                </div>
              </div>
            </div>
          </div>
        )}
        {selectedProfileNav === "Dashboard" && (
          <div className={classNames.tableContainer}>
            <div className={classNames.headContainer}>
              <div
                className={classNames.title}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ fontWeight: "700" }}>
                  {!isToggled ? "Clients List" : "All Clients"}
                </div>
                <div
                  className={classNames["toggle-switch"]}
                  onClick={handleToggle}
                >
                  <div
                    className={`${classNames.slider} ${
                      isToggled ? classNames.active : ""
                    }`}
                  ></div>
                </div>
              </div>
              <div className={classNames.filterButtons}>
                <div className={classNames.leftContainer}>
                  {/* <div className={classNames.buttons}>Onboard Client</div> */}
                  <div className={classNames.buttons}>Export</div>
                </div>
                <div className={classNames.rightContainer}>
                  <div className={classNames.searchDiv}>
                    <IoMdSearch />
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(event) => setSearchQuery(event?.target?.value)}
                    />
                  </div>
                  <CustomDropdown
                    buttonText={selectedTab ? selectedTab : "View: All"}
                    // color="var(--main-color)"
                    width={"200px"}
                    dropdownItems={[
                      { title: "All", clickable: true, color: "gray" },
                      {
                        title: "Active",
                        clickable: true,
                        color: "var(--opacity-main)",
                      },
                      {
                        title: "In Process",
                        clickable: true,
                        color: "#FFCD71",
                      },
                      { title: "Inactive", clickable: true, color: "#DF0404" },
                    ]}
                    stateValue={selectedTab}
                    setState={setSelectedTab}
                    typee={"v3"}
                  />
                  <CustomDropdown
                    buttonText={selectedTab1 ? selectedTab1 : "Sort By: Newest"}
                    // color="var(--main-color)"
                    dropdownItems={[
                      { title: "Newest", clickable: true },
                      { title: "Oldest", clickable: true },
                    ]}
                    stateValue={selectedTab1}
                    setState={setSelectedTab1}
                    typee={"v3"}
                    width={"200px"}
                  />
                </div>
              </div>
            </div>
            <div className={classNames.proactiveDetails}>
              <div className={classNames.otherGrids}>
                <div className={classNames.trackerTable}>
                  <div className={classNames.tableParent}>
                    <div
                      className={`${classNames.tableTitles} stickyTableTitles`}
                    >
                      <div></div>
                      {/* <div>
                      <CustomCheckbox
                        actionBtns={actionBtns}
                        setActionBtns={setActionBtns}
                        id={"test id"}
                        name={"test id name"}
                      />
                    </div> */}
                      <div>Customer Name</div>
                      <div>Company</div>
                      <div>Contact</div>
                      <div>Email </div>
                      <div>City</div>
                      <div>
                        Status
                        {/* {isToggled ? "Hierarchy" : "Status"} */}
                      </div>
                    </div>
                    {!isToggled ? (
                      <div
                        className={classNames.tableBody}
                        style={{ height: "calc(100% - 36px)" }}
                      >
                        {allSelectedPolicyClaimsLoading ? (
                          <div className={classNames.eachItem}>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                          </div>
                        ) : allSelectedPolicyClaims?.length > 0 ? (
                          allSelectedPolicyClaims
                            ?.filter((row) => {
                              // Convert the row object values to an array and check if any value matches the search query
                              const values = Object?.values(row);
                              const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                              return values?.some((value) => {
                                if (typeof value === "string") {
                                  return value
                                    ?.toLowerCase()
                                    ?.includes(searchQueryy);
                                }
                                return false;
                              });
                            })
                            ?.map((eachItem, index) => {
                              return (
                                <>
                                  <div
                                    className={classNames.eachItem}
                                    key={eachItem?.first_name + index}
                                    style={{
                                      position: "relative",
                                      borderBottom: !selectedMenu
                                        ? "1px solid #e7e7e7"
                                        : "",
                                    }}
                                  >
                                    <div
                                      className={classNames.verticalDots}
                                      style={{ alignItems: "center" }}
                                    >
                                      {actionBtns?.includes(
                                        "checkarrayThreeDots" + index
                                      ) ||
                                      selectedRow?.lead_id ===
                                        eachItem?.lead_id ? (
                                        <IoMdClose
                                          onClick={() => {
                                            setActionBtns([]);
                                            setSelectedRow(null);
                                            setSelectedMenu(null);
                                          }}
                                        />
                                      ) : (
                                        <BsThreeDotsVertical
                                          onClick={() => {
                                            setSelectedRow(eachItem);
                                            setActionBtns(
                                              "checkarrayThreeDots" + index
                                            );
                                          }}
                                        />
                                      )}

                                      {actionBtns?.includes(
                                        "checkarrayThreeDots" + index
                                      ) &&
                                        selectedRow?.lead_id ===
                                          eachItem?.lead_id &&
                                        !selectedMenu &&
                                        (eachItem?.quote_status ===
                                          "No-Quotes" ||
                                        eachItem?.quote_status ===
                                          "Completed" ||
                                        eachItem?.quote_status ===
                                          "In-Process" ? (
                                          <div
                                            className={
                                              classNames.dropdownContent
                                            }
                                            ref={selectedItemTable}
                                          >
                                            {eachItem?.quote_status ===
                                            "No-Quotes"
                                              ? inactiveMenu?.map(
                                                  (eachItem, index) => {
                                                    return (
                                                      <div
                                                        className={
                                                          classNames.eachFilterItem
                                                        }
                                                        key={
                                                          eachItem?.title +
                                                          index
                                                        }
                                                        style={{
                                                          pointerEvents:
                                                            eachItem?.clickable
                                                              ? ""
                                                              : "none",
                                                          opacity:
                                                            eachItem?.clickable
                                                              ? ""
                                                              : "0.5",
                                                        }}
                                                        onClick={(e) => {
                                                          setPopup(eachItem);
                                                          setSelectedMenu(
                                                            eachItem.title
                                                          );
                                                        }}
                                                      >
                                                        <span>
                                                          {eachItem.icon && (
                                                            <eachItem.icon />
                                                          )}
                                                          {eachItem?.title}
                                                        </span>{" "}
                                                        {eachItem?.subMenu && (
                                                          <IoMdArrowDropdown />
                                                        )}
                                                        {eachItem?.subMenu
                                                          ?.length > 0 &&
                                                          Array.isArray(
                                                            eachItem?.subMenu
                                                          ) && (
                                                            <div
                                                              className={`${classNames.dropdownContent} ${classNames.subDropdown}`}
                                                            >
                                                              {eachItem?.subMenu?.map(
                                                                (
                                                                  eachItemSub,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <div
                                                                      className={
                                                                        classNames.eachFilterItem
                                                                      }
                                                                      key={
                                                                        eachItemSub?.title +
                                                                        index
                                                                      }
                                                                      style={{
                                                                        pointerEvents:
                                                                          eachItemSub?.clickable
                                                                            ? ""
                                                                            : "none",
                                                                        opacity:
                                                                          eachItemSub?.clickable
                                                                            ? ""
                                                                            : "0.5",
                                                                        justifyContent:
                                                                          "flex-start",
                                                                        gap: "10px",
                                                                      }}
                                                                      onClick={() => {
                                                                        history.push(
                                                                          eachItemSub?.link
                                                                        );
                                                                      }}
                                                                    >
                                                                      <span>
                                                                        {eachItemSub.icon && (
                                                                          <eachItemSub.icon />
                                                                        )}
                                                                      </span>
                                                                      {
                                                                        eachItemSub?.title
                                                                      }{" "}
                                                                      {eachItemSub?.subMenu && (
                                                                        <IoMdArrowDropdown />
                                                                      )}
                                                                    </div>
                                                                  );
                                                                }
                                                              )}
                                                            </div>
                                                          )}
                                                      </div>
                                                    );
                                                  }
                                                )
                                              : eachItem?.quote_status ===
                                                "In-Process"
                                              ? inProcessMenu?.map(
                                                  (eachItem, index) => {
                                                    return (
                                                      <div
                                                        className={
                                                          classNames.eachFilterItem
                                                        }
                                                        key={
                                                          eachItem?.title +
                                                          index
                                                        }
                                                        style={{
                                                          pointerEvents:
                                                            eachItem?.clickable
                                                              ? ""
                                                              : "none",
                                                          opacity:
                                                            eachItem?.clickable
                                                              ? ""
                                                              : "0.5",
                                                        }}
                                                        onClick={(e) => {
                                                          setPopup(eachItem);
                                                          setSelectedMenu(
                                                            eachItem.title
                                                          );
                                                        }}
                                                      >
                                                        <span>
                                                          {eachItem.icon && (
                                                            <eachItem.icon />
                                                          )}
                                                          {eachItem?.title}
                                                        </span>{" "}
                                                        {eachItem?.subMenu && (
                                                          <IoMdArrowDropdown />
                                                        )}
                                                        {eachItem?.subMenu
                                                          ?.length > 0 &&
                                                          Array.isArray(
                                                            eachItem?.subMenu
                                                          ) && (
                                                            <div
                                                              className={`${classNames.dropdownContent} ${classNames.subDropdown}`}
                                                            >
                                                              {eachItem?.subMenu?.map(
                                                                (
                                                                  eachItemSub,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <div
                                                                      className={
                                                                        classNames.eachFilterItem
                                                                      }
                                                                      key={
                                                                        eachItemSub?.title +
                                                                        index
                                                                      }
                                                                      style={{
                                                                        pointerEvents:
                                                                          eachItemSub?.clickable
                                                                            ? ""
                                                                            : "none",
                                                                        opacity:
                                                                          eachItemSub?.clickable
                                                                            ? ""
                                                                            : "0.5",
                                                                        justifyContent:
                                                                          "flex-start",
                                                                        gap: "10px",
                                                                      }}
                                                                      onClick={() => {
                                                                        history.push(
                                                                          eachItemSub?.link
                                                                        );
                                                                      }}
                                                                    >
                                                                      <span>
                                                                        {eachItemSub.icon && (
                                                                          <eachItemSub.icon />
                                                                        )}
                                                                      </span>
                                                                      {
                                                                        eachItemSub?.title
                                                                      }{" "}
                                                                      {eachItemSub?.subMenu && (
                                                                        <IoMdArrowDropdown />
                                                                      )}
                                                                    </div>
                                                                  );
                                                                }
                                                              )}
                                                            </div>
                                                          )}
                                                      </div>
                                                    );
                                                  }
                                                )
                                              : eachItem?.quote_status ===
                                                "Completed"
                                              ? filterItemsDashboard?.map(
                                                  (eachItem, index) => {
                                                    return (
                                                      <div
                                                        className={
                                                          classNames.eachFilterItem
                                                        }
                                                        key={
                                                          eachItem?.title +
                                                          index
                                                        }
                                                        style={{
                                                          pointerEvents:
                                                            eachItem?.clickable
                                                              ? ""
                                                              : "none",
                                                          opacity:
                                                            eachItem?.clickable
                                                              ? ""
                                                              : "0.5",
                                                        }}
                                                        onClick={() => {
                                                          if (
                                                            eachItem?.title ==
                                                            "Initiate Endorsements"
                                                          ) {
                                                            history.push(
                                                              "/app/agency/cdbalance"
                                                            );
                                                          } else {
                                                            setSelectedMenu(
                                                              eachItem?.title
                                                            );
                                                            setPopup(eachItem);
                                                          }
                                                        }}
                                                      >
                                                        <span>
                                                          {eachItem.icon && (
                                                            <eachItem.icon />
                                                          )}
                                                          {eachItem?.title}
                                                        </span>{" "}
                                                        {eachItem?.subMenu && (
                                                          <IoMdArrowDropdown />
                                                        )}
                                                        {eachItem?.subMenu
                                                          ?.length > 0 &&
                                                          Array.isArray(
                                                            eachItem?.subMenu
                                                          ) && (
                                                            <div
                                                              className={`${classNames.dropdownContent} ${classNames.subDropdown}`}
                                                            >
                                                              {eachItem?.subMenu?.map(
                                                                (
                                                                  eachItemSub,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <div
                                                                      className={
                                                                        classNames.eachFilterItem
                                                                      }
                                                                      key={
                                                                        eachItemSub?.title +
                                                                        index
                                                                      }
                                                                      style={{
                                                                        pointerEvents:
                                                                          eachItemSub?.clickable
                                                                            ? ""
                                                                            : "none",
                                                                        opacity:
                                                                          eachItemSub?.clickable
                                                                            ? ""
                                                                            : "0.5",
                                                                        justifyContent:
                                                                          "flex-start",
                                                                        gap: "10px",
                                                                      }}
                                                                      onClick={() => {
                                                                        history.push(
                                                                          eachItemSub?.link
                                                                        );
                                                                      }}
                                                                    >
                                                                      <span>
                                                                        {eachItemSub.icon && (
                                                                          <eachItemSub.icon />
                                                                        )}
                                                                      </span>
                                                                      {
                                                                        eachItemSub?.title
                                                                      }{" "}
                                                                      {eachItemSub?.subMenu && (
                                                                        <IoMdArrowDropdown />
                                                                      )}
                                                                    </div>
                                                                  );
                                                                }
                                                              )}
                                                            </div>
                                                          )}
                                                      </div>
                                                    );
                                                  }
                                                )
                                              : ""}
                                          </div>
                                        ) : (
                                          ""
                                        ))}
                                    </div>

                                    <div className={classNames.singleFields}>
                                      {eachItem?.first_name
                                        ? eachItem?.first_name
                                        : "" + eachItem?.last_name
                                        ? eachItem?.last_name
                                        : ""}
                                    </div>
                                    <div className={classNames.singleFields}>
                                      {eachItem?.company_name
                                        ? eachItem?.company_name
                                        : ""}
                                    </div>
                                    <div className={classNames.singleFields}>
                                      {eachItem?.phone_number
                                        ? eachItem?.phone_number
                                        : ""}
                                    </div>
                                    <div className={classNames.singleFields}>
                                      {eachItem?.lead_email
                                        ? eachItem?.lead_email
                                        : ""}
                                    </div>
                                    <div className={classNames.singleFields}>
                                      {eachItem?.city ? eachItem?.city : ""}
                                    </div>
                                    <div
                                      className={classNames.singleFields}
                                      style={{
                                        padding: "5px 0",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        paddingRight: "10px",
                                      }}
                                    >
                                      <div
                                        className={classNames.statusButton}
                                        style={{
                                          background:
                                            eachItem?.quote_status ===
                                            "Completed"
                                              ? "var(--main-color)"
                                              : eachItem?.quote_status ===
                                                "In-Process"
                                              ? "#FFCD71"
                                              : eachItem?.quote_status ===
                                                "No-Quotes"
                                              ? "#DF0404"
                                              : "var(--main-color)",
                                        }}
                                      >
                                        {eachItem?.quote_status === "Completed"
                                          ? "Active"
                                          : eachItem?.quote_status ===
                                            "In-Process"
                                          ? "In Process"
                                          : eachItem?.quote_status ===
                                            "No-Quotes"
                                          ? "Inactive"
                                          : ""}
                                      </div>
                                      {
                                        showDeleteConf &&
                                        showDeleteConf.lead_id ===
                                          eachItem?.lead_id ? (
                                          <IoMdClose
                                            style={{
                                              width: "15px",
                                              height: "19px",
                                            }}
                                            onClick={() => {
                                              setShowDeleteConf(null);
                                            }}
                                          />
                                        ) : null

                                        // <DeleteIcon
                                        //     onClick={(e) => {
                                        //       setShowDeleteConf(eachItem);
                                        //       console.log(
                                        //         eachItem,
                                        //         "lkjewhlfhwlefhw"
                                        //       );
                                        //     }}
                                        //     style={{
                                        //       width: "15px",
                                        //       height: "19px",
                                        //     }}
                                        //   />
                                      }
                                    </div>
                                  </div>
                                  <div className={classNames.singleFields}>
                                    {showDeleteConf &&
                                      showDeleteConf.lead_id ===
                                        eachItem?.lead_id && (
                                        <>
                                          <div
                                            className={classNames.overlay}
                                            onClick={(e) => {
                                              setShowDeleteConf(null);
                                            }}
                                          ></div>
                                          <div
                                            className={classNames.delConfirm}
                                          >
                                            <div
                                              className={classNames.popupTitle}
                                            >
                                              Are you sure you want to delete
                                              &nbsp;
                                              {eachItem?.lead_email
                                                ? eachItem?.lead_email
                                                : "email"}
                                            </div>
                                            <div
                                              className={classNames.popupOption}
                                              onClick={(e) => {
                                                handleDelete(eachItem);
                                              }}
                                            >
                                              Yes
                                            </div>
                                            <div
                                              className={classNames.popupOption}
                                              onClick={(e) => {
                                                setShowDeleteConf(null);
                                              }}
                                            >
                                              No
                                            </div>
                                          </div>
                                        </>
                                      )}
                                  </div>
                                  {/* {selectedRow?.lead_id === eachItem?.lead_id &&
                              popup?.title === "See Client Details" && (
                                <div>Hello</div>
                              )} */}
                                  {selectedMenu === "See Client Details" ||
                                  selectedMenu === "See lead details" ? (
                                    <div style={{ display: "block" }}>
                                      {selectedRow &&
                                        selectedRow?.lead_id ===
                                          eachItem.lead_id && (
                                          <ClientDetails
                                            selectedRow={selectedRow}
                                          />
                                        )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {selectedMenu === "See policies" ? (
                                    <div style={{ display: "block" }}>
                                      {selectedRow &&
                                        selectedRow?.lead_id ===
                                          eachItem.lead_id && (
                                          <PolicyDetails
                                            setPopup={setPopup}
                                            selectedRow={selectedRow}
                                          />
                                        )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div
                        className={classNames.tableBody}
                        style={{ height: "calc(100% - 36px)" }}
                      >
                        {allSelectedPolicyClaimsLoading ? (
                          <div className={classNames.eachItem}>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                            <div className={classNames.singleFields}>
                              <Skeleton height={15} width={80} />
                            </div>
                          </div>
                        ) : allAssociateLeads?.length > 0 ? (
                          allAssociateLeads
                            ?.filter((row) => {
                              // Convert the row object values to an array and check if any value matches the search query
                              const values = Object?.values(row);
                              const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                              return values?.some((value) => {
                                if (typeof value === "string") {
                                  return value
                                    ?.toLowerCase()
                                    ?.includes(searchQueryy);
                                }
                                return false;
                              });
                            })
                            ?.map((eachItem, index) => {
                              return (
                                <>
                                  <div
                                    className={classNames.eachItem}
                                    key={eachItem?.first_name + index}
                                    style={{
                                      position: "relative",
                                      borderBottom: !showAllLeadData
                                        ? "1px solid #e7e7e7"
                                        : "",
                                    }}
                                  >
                                    <div
                                      className={classNames.verticalDots}
                                      style={{ alignItems: "center" }}
                                    >
                                      {actionBtns?.includes(
                                        "checkarrayThreeDots" + index
                                      ) ||
                                      showAllLeadData?.lead_id ===
                                        eachItem?.lead_id ? (
                                        <IoMdClose
                                          onClick={() => {
                                            setShowAllLeadData(null);
                                          }}
                                        />
                                      ) : (
                                        <BsThreeDotsVertical
                                          onClick={() => {
                                            setShowAllLeadData(eachItem);
                                          }}
                                        />
                                      )}
                                    </div>

                                    <div className={classNames.singleFields}>
                                      {eachItem?.first_name
                                        ? eachItem?.first_name
                                        : "" + eachItem?.last_name
                                        ? eachItem?.last_name
                                        : ""}
                                    </div>
                                    <div className={classNames.singleFields}>
                                      {eachItem?.company_name
                                        ? eachItem?.company_name.length > 10
                                          ? eachItem?.company_name?.substring(
                                              0,
                                              10
                                            ) + "..."
                                          : eachItem?.company_name
                                        : ""}
                                    </div>
                                    <div className={classNames.singleFields}>
                                      {eachItem?.phone_number
                                        ? eachItem?.phone_number
                                        : ""}
                                    </div>
                                    <div className={classNames.singleFields}>
                                      {eachItem?.lead_email
                                        ? eachItem?.lead_email
                                        : ""}
                                    </div>
                                    <div className={classNames.singleFields}>
                                      {eachItem?.city ? eachItem?.city : ""}
                                    </div>
                                    <div
                                      className={classNames.singleFields}
                                      style={{
                                        padding: "5px 0",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        paddingRight: "10px",
                                      }}
                                    >
                                      <div
                                        onClick={(e) => {
                                          if (
                                            eachItem?.quote_status?.length < 1
                                          ) {
                                            initiateLead(eachItem);
                                          }
                                        }}
                                        className={classNames.statusButton}
                                        style={{
                                          background:
                                            eachItem?.quote_status ===
                                            "Completed"
                                              ? "var(--main-color)"
                                              : eachItem?.quote_status ===
                                                "In-Process"
                                              ? "#FFCD71"
                                              : eachItem?.quote_status ===
                                                "No-Quotes"
                                              ? "#DF0404"
                                              : "var(--main-color)",
                                        }}
                                      >
                                        {/* {eachItem?.quote_status
                                          ?.length > 0
                                          ? "Initiated"
                                          : "Uninitiated"} */}
                                        {eachItem?.quote_status === "Completed"
                                          ? "Active"
                                          : eachItem?.quote_status ===
                                            "In-Process"
                                          ? "In Process"
                                          : eachItem?.quote_status ===
                                            "No-Quotes"
                                          ? "Inactive"
                                          : ""}
                                      </div>
                                    </div>
                                  </div>

                                  {showAllLeadData?.lead_id ===
                                  eachItem?.lead_id ? (
                                    <div
                                      style={{
                                        borderBottom: showAllLeadData
                                          ? "1px solid #e7e7e7"
                                          : "",
                                      }}
                                    >
                                      <div
                                        style={{
                                          padding: "10px 20px",
                                          height: "270px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "grid",
                                            gridTemplateColumns: "1fr 1fr 1fr",
                                            gap: 20,
                                          }}
                                        >
                                          <div>
                                            <div
                                              className={
                                                classNames.userDetailTitle
                                              }
                                            >
                                              Sales Associate
                                            </div>
                                            <div
                                              className={
                                                classNames.userDetailValue1
                                              }
                                            >
                                              {conditionalClientData(
                                                showAllLeadData,
                                                "Associates",
                                                "Sales"
                                              )}
                                            </div>
                                          </div>
                                          <div>
                                            <div
                                              className={
                                                classNames.userDetailTitle
                                              }
                                            >
                                              Service Associate
                                            </div>
                                            <div
                                              className={
                                                classNames.userDetailValue1
                                              }
                                            >
                                              {conditionalClientData(
                                                showAllLeadData,
                                                "Associates",
                                                "Service"
                                              )}
                                            </div>
                                          </div>
                                          <div>
                                            <div
                                              className={
                                                classNames.userDetailTitle
                                              }
                                            >
                                              Affiliate
                                            </div>
                                            <div
                                              className={
                                                classNames.userDetailValue1
                                              }
                                            >
                                              {conditionalClientData(
                                                showAllLeadData,
                                                "Business Head",
                                                "Service"
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            display: "grid",
                                            gridTemplateColumns: "1fr 1fr 1fr",
                                            gap: 20,
                                            paddingTop: "20px",
                                          }}
                                        >
                                          <div>
                                            <div
                                              className={
                                                classNames.userDetailTitle
                                              }
                                            >
                                              Team Lead
                                            </div>
                                            <div
                                              className={
                                                classNames.userDetailValue1
                                              }
                                            >
                                              {conditionalClientData(
                                                showAllLeadData,
                                                "Team Leader",
                                                "Sales"
                                              )}
                                            </div>
                                          </div>
                                          <div>
                                            <div
                                              className={
                                                classNames.userDetailTitle
                                              }
                                            >
                                              Vertical Head
                                            </div>
                                            <div
                                              className={
                                                classNames.userDetailValue1
                                              }
                                            >
                                              {conditionalClientData(
                                                showAllLeadData,
                                                "Vertical Head",
                                                "Sales"
                                              )}
                                            </div>
                                          </div>
                                          <div>
                                            <div
                                              className={
                                                classNames.userDetailTitle
                                              }
                                            >
                                              Business Head
                                            </div>
                                            <div
                                              className={
                                                classNames.userDetailValue1
                                              }
                                            >
                                              {conditionalClientData(
                                                showAllLeadData,
                                                "Business Head",
                                                "Sales"
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames.paginationBar}>
              <div className={classNames.paginationMsg}>
                {/* Showing data 1 to 8 of 250 entries */}
                Showing{" "}
                {!isToggled
                  ? allSelectedPolicyClaims?.length
                  : allAssociateLeads?.length}{" "}
                entries
              </div>
            </div>
          </div>
        )}
        {selectedProfileNav === "Team" && (
          <div className={classNames.tableContainer}>
            <div className={classNames.headContainer}>
              <div
                className={classNames.title}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ fontWeight: "700" }}>All Team Members</div>
                {/* <div
                  className={classNames["toggle-switch"]}
                  onClick={handleToggle}
                >
                  <div
                    className={`${classNames.slider} ${
                      isToggled ? classNames.active : ""
                    }`}
                  ></div>
                </div> */}
              </div>
              <div className={classNames.filterButtons}>
                <div className={classNames.leftContainer}>
                  <div
                    className={classNames.buttons}
                    onClick={() =>
                      setPopup({ title: "Add Cornerstone Employee" })
                    }
                  >
                    Add New
                  </div>
                </div>
                <div className={classNames.rightContainer}>
                  <div className={classNames.searchDiv}>
                    <IoMdSearch />
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(event) => setSearchQuery(event?.target?.value)}
                    />
                  </div>
                  <CustomDropdown
                    buttonText={selectedTab ? selectedTab : "View: All"}
                    // color="var(--main-color)"
                    width={"200px"}
                    dropdownItems={[
                      { title: "All", clickable: true },
                      { title: "Sales Associates", clickable: true },
                      { title: "Team Leads", clickable: true },
                      { title: "Business Heads", clickable: true },
                      { title: "Vertical Heads", clickable: true },
                    ]}
                    stateValue={selectedTab}
                    setState={setSelectedTab}
                    typee={"v3"}
                  />
                  <CustomDropdown
                    buttonText={selectedTab1 ? selectedTab1 : "Sort By: Newest"}
                    // color="var(--main-color)"
                    dropdownItems={[
                      { title: "Newest", clickable: true },
                      { title: "Oldest", clickable: true },
                    ]}
                    stateValue={selectedTab1}
                    setState={setSelectedTab1}
                    typee={"v3"}
                    width={"200px"}
                  />
                </div>
              </div>
            </div>
            <div className={classNames.proactiveDetails}>
              <div className={classNames.otherGrids}>
                <div className={classNames.trackerTable}>
                  <div className={classNames.tableParent}>
                    <div
                      className={`${classNames.tableTitles1} stickyTableTitles`}
                    >
                      <div></div>
                      {/* <div>
                      <CustomCheckbox
                        actionBtns={actionBtns}
                        setActionBtns={setActionBtns}
                        id={"test id"}
                        name={"test id name"}
                      />
                    </div> */}
                      <div>Name</div>
                      <div>Email</div>
                      <div>Phone Number</div>
                      <div>Users </div>
                      <div>Roles</div>
                    </div>
                    <div
                      className={classNames.tableBody1}
                      style={{ height: "calc(100% - 36px)" }}
                    >
                      {teamTableDataLoading ? (
                        <div className={classNames.eachItem}>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                          <div className={classNames.singleFields}>
                            <Skeleton height={15} width={80} />
                          </div>
                        </div>
                      ) : teamTableData?.length > 0 ? (
                        teamTableData
                          ?.filter((row) => {
                            // Convert the row object values to an array and check if any value matches the search query
                            const values = Object?.values(row);
                            const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                            return values?.some((value) => {
                              if (typeof value === "string") {
                                return value
                                  ?.toLowerCase()
                                  ?.includes(searchQueryy);
                              }
                              return false;
                            });
                          })
                          ?.map((eachItem, index) => {
                            return (
                              <>
                                <div
                                  className={classNames.eachItem}
                                  key={eachItem?.first_name + index}
                                  style={{
                                    position: "relative",
                                    borderBottom: !selectedMenu
                                      ? "1px solid #e7e7e7"
                                      : "",
                                  }}
                                >
                                  <div
                                    className={classNames.verticalDots}
                                    style={{ alignItems: "center" }}
                                  >
                                    {actionBtns?.includes(
                                      "checkarrayThreeDots" + index
                                    ) || selectedRow?._id === eachItem?._id ? (
                                      <IoMdClose
                                        onClick={() => {
                                          setActionBtns([]);
                                          setSelectedRow(null);
                                          setSelectedMenu(null);
                                          setPopup(null);
                                          setAllExistingRoleForUser([]);
                                        }}
                                      />
                                    ) : (
                                      <BsThreeDotsVertical
                                        onClick={() => {
                                          setSelectedRow(eachItem);
                                          setActionBtns(
                                            "checkarrayThreeDots" + index
                                          );
                                        }}
                                      />
                                    )}
                                    {popup?.title === "Add role" &&
                                    selectedRow?._id === eachItem?._id ? (
                                      <div
                                        className={classNames.dropdownContent}
                                        ref={selectedItemTable}
                                      >
                                        <AddRole
                                          mapItems={allPossibleRoles}
                                          setState={setPopup}
                                          setLocalRefresh={setLocalRefresh}
                                          finalFunc={() => {
                                            setActionBtns([]);
                                            setSelectedRow(null);
                                            setSelectedMenu(null);
                                            setAllExistingRoleForUser([]);
                                          }}
                                          selectedRow={selectedRow}
                                        />
                                      </div>
                                    ) : popup &&
                                      popup?.title === "Remove role" &&
                                      selectedRow?._id === eachItem?._id ? (
                                      <div
                                        className={classNames.dropdownContent}
                                        ref={selectedItemTable}
                                      >
                                        <RemoveRole
                                          mapItems={allExistingRoleForUser}
                                          setState={setPopup}
                                          setLocalRefresh={setLocalRefresh}
                                          finalFunc={() => {
                                            setActionBtns([]);
                                            setSelectedRow(null);
                                            setSelectedMenu(null);
                                            setAllExistingRoleForUser([]);
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      actionBtns?.includes(
                                        "checkarrayThreeDots" + index
                                      ) &&
                                      selectedRow?._id === eachItem?._id &&
                                      !selectedMenu &&
                                      (eachItem?.quote_status === "No-Quotes" ||
                                      eachItem?.quote_status === "Completed" ||
                                      eachItem?.quote_status ===
                                        "In-Process" ? (
                                        <div
                                          className={classNames.dropdownContent}
                                          ref={selectedItemTable}
                                        >
                                          {eachItem?.quote_status ===
                                          "No-Quotes"
                                            ? inactiveMenu?.map(
                                                (eachItem, index) => {
                                                  return (
                                                    <div
                                                      className={
                                                        classNames.eachFilterItem
                                                      }
                                                      key={
                                                        eachItem?.title + index
                                                      }
                                                      style={{
                                                        pointerEvents:
                                                          eachItem?.clickable
                                                            ? ""
                                                            : "none",
                                                        opacity:
                                                          eachItem?.clickable
                                                            ? ""
                                                            : "0.5",
                                                      }}
                                                      onClick={(e) => {
                                                        setPopup(eachItem);
                                                        setSelectedMenu(
                                                          eachItem.title
                                                        );
                                                      }}
                                                    >
                                                      <span>
                                                        {eachItem.icon && (
                                                          <eachItem.icon />
                                                        )}
                                                        {eachItem?.title}
                                                      </span>{" "}
                                                      {eachItem?.subMenu && (
                                                        <IoMdArrowDropdown />
                                                      )}
                                                      {eachItem?.subMenu
                                                        ?.length > 0 &&
                                                        Array.isArray(
                                                          eachItem?.subMenu
                                                        ) && (
                                                          <div
                                                            className={`${classNames.dropdownContent} ${classNames.subDropdown}`}
                                                          >
                                                            {eachItem?.subMenu?.map(
                                                              (
                                                                eachItemSub,
                                                                index
                                                              ) => {
                                                                return (
                                                                  <div
                                                                    className={
                                                                      classNames.eachFilterItem
                                                                    }
                                                                    key={
                                                                      eachItemSub?.title +
                                                                      index
                                                                    }
                                                                    style={{
                                                                      pointerEvents:
                                                                        eachItemSub?.clickable
                                                                          ? ""
                                                                          : "none",
                                                                      opacity:
                                                                        eachItemSub?.clickable
                                                                          ? ""
                                                                          : "0.5",
                                                                      justifyContent:
                                                                        "flex-start",
                                                                      gap: "10px",
                                                                    }}
                                                                    onClick={() => {
                                                                      history.push(
                                                                        eachItemSub?.link
                                                                      );
                                                                    }}
                                                                  >
                                                                    <span>
                                                                      {eachItemSub.icon && (
                                                                        <eachItemSub.icon />
                                                                      )}
                                                                    </span>
                                                                    {
                                                                      eachItemSub?.title
                                                                    }{" "}
                                                                    {eachItemSub?.subMenu && (
                                                                      <IoMdArrowDropdown />
                                                                    )}
                                                                  </div>
                                                                );
                                                              }
                                                            )}
                                                          </div>
                                                        )}
                                                    </div>
                                                  );
                                                }
                                              )
                                            : eachItem?.quote_status ===
                                              "In-Process"
                                            ? inProcessMenu?.map(
                                                (eachItem, index) => {
                                                  return (
                                                    <div
                                                      className={
                                                        classNames.eachFilterItem
                                                      }
                                                      key={
                                                        eachItem?.title + index
                                                      }
                                                      style={{
                                                        pointerEvents:
                                                          eachItem?.clickable
                                                            ? ""
                                                            : "none",
                                                        opacity:
                                                          eachItem?.clickable
                                                            ? ""
                                                            : "0.5",
                                                      }}
                                                      onClick={(e) => {
                                                        setPopup(eachItem);
                                                        setSelectedMenu(
                                                          eachItem.title
                                                        );
                                                      }}
                                                    >
                                                      <span>
                                                        {eachItem.icon && (
                                                          <eachItem.icon />
                                                        )}
                                                        {eachItem?.title}
                                                      </span>{" "}
                                                      {eachItem?.subMenu && (
                                                        <IoMdArrowDropdown />
                                                      )}
                                                      {eachItem?.subMenu
                                                        ?.length > 0 &&
                                                        Array.isArray(
                                                          eachItem?.subMenu
                                                        ) && (
                                                          <div
                                                            className={`${classNames.dropdownContent} ${classNames.subDropdown}`}
                                                          >
                                                            {eachItem?.subMenu?.map(
                                                              (
                                                                eachItemSub,
                                                                index
                                                              ) => {
                                                                return (
                                                                  <div
                                                                    className={
                                                                      classNames.eachFilterItem
                                                                    }
                                                                    key={
                                                                      eachItemSub?.title +
                                                                      index
                                                                    }
                                                                    style={{
                                                                      pointerEvents:
                                                                        eachItemSub?.clickable
                                                                          ? ""
                                                                          : "none",
                                                                      opacity:
                                                                        eachItemSub?.clickable
                                                                          ? ""
                                                                          : "0.5",
                                                                      justifyContent:
                                                                        "flex-start",
                                                                      gap: "10px",
                                                                    }}
                                                                    onClick={() => {
                                                                      history.push(
                                                                        eachItemSub?.link
                                                                      );
                                                                    }}
                                                                  >
                                                                    <span>
                                                                      {eachItemSub.icon && (
                                                                        <eachItemSub.icon />
                                                                      )}
                                                                    </span>
                                                                    {
                                                                      eachItemSub?.title
                                                                    }{" "}
                                                                    {eachItemSub?.subMenu && (
                                                                      <IoMdArrowDropdown />
                                                                    )}
                                                                  </div>
                                                                );
                                                              }
                                                            )}
                                                          </div>
                                                        )}
                                                    </div>
                                                  );
                                                }
                                              )
                                            : eachItem?.quote_status ===
                                              "Completed"
                                            ? filterItemsDashboard?.map(
                                                (eachItem, index) => {
                                                  return (
                                                    <div
                                                      className={
                                                        classNames.eachFilterItem
                                                      }
                                                      key={
                                                        eachItem?.title + index
                                                      }
                                                      style={{
                                                        pointerEvents:
                                                          eachItem?.clickable
                                                            ? ""
                                                            : "none",
                                                        opacity:
                                                          eachItem?.clickable
                                                            ? ""
                                                            : "0.5",
                                                      }}
                                                      onClick={() => {
                                                        if (
                                                          eachItem?.title ==
                                                          "Initiate Endorsements"
                                                        ) {
                                                          history.push(
                                                            "/app/agency/cdbalance"
                                                          );
                                                        } else {
                                                          setSelectedMenu(
                                                            eachItem?.title
                                                          );
                                                          setPopup(eachItem);
                                                        }
                                                      }}
                                                    >
                                                      <span>
                                                        {eachItem.icon && (
                                                          <eachItem.icon />
                                                        )}
                                                        {eachItem?.title}
                                                      </span>{" "}
                                                      {eachItem?.subMenu && (
                                                        <IoMdArrowDropdown />
                                                      )}
                                                      {eachItem?.subMenu
                                                        ?.length > 0 &&
                                                        Array.isArray(
                                                          eachItem?.subMenu
                                                        ) && (
                                                          <div
                                                            className={`${classNames.dropdownContent} ${classNames.subDropdown}`}
                                                          >
                                                            {eachItem?.subMenu?.map(
                                                              (
                                                                eachItemSub,
                                                                index
                                                              ) => {
                                                                return (
                                                                  <div
                                                                    className={
                                                                      classNames.eachFilterItem
                                                                    }
                                                                    key={
                                                                      eachItemSub?.title +
                                                                      index
                                                                    }
                                                                    style={{
                                                                      pointerEvents:
                                                                        eachItemSub?.clickable
                                                                          ? ""
                                                                          : "none",
                                                                      opacity:
                                                                        eachItemSub?.clickable
                                                                          ? ""
                                                                          : "0.5",
                                                                      justifyContent:
                                                                        "flex-start",
                                                                      gap: "10px",
                                                                    }}
                                                                    onClick={() => {
                                                                      history.push(
                                                                        eachItemSub?.link
                                                                      );
                                                                    }}
                                                                  >
                                                                    <span>
                                                                      {eachItemSub.icon && (
                                                                        <eachItemSub.icon />
                                                                      )}
                                                                    </span>
                                                                    {
                                                                      eachItemSub?.title
                                                                    }{" "}
                                                                    {eachItemSub?.subMenu && (
                                                                      <IoMdArrowDropdown />
                                                                    )}
                                                                  </div>
                                                                );
                                                              }
                                                            )}
                                                          </div>
                                                        )}
                                                    </div>
                                                  );
                                                }
                                              )
                                            : ""}
                                        </div>
                                      ) : (
                                        <div
                                          className={classNames.dropdownContent}
                                          ref={selectedItemTable}
                                        >
                                          {teamMembersMain?.map(
                                            (eachItem, index) => {
                                              return (
                                                <div
                                                  className={
                                                    classNames.eachFilterItem
                                                  }
                                                  key={eachItem?.title + index}
                                                  style={{
                                                    pointerEvents:
                                                      eachItem?.clickable
                                                        ? ""
                                                        : "none",
                                                    opacity: eachItem?.clickable
                                                      ? ""
                                                      : "0.5",
                                                  }}
                                                  onClick={(e) => {
                                                    setPopup(eachItem);
                                                    setSelectedMenu(
                                                      eachItem.title
                                                    );
                                                  }}
                                                >
                                                  <span>
                                                    {eachItem.icon && (
                                                      <eachItem.icon />
                                                    )}
                                                    {eachItem?.title}
                                                  </span>{" "}
                                                  {eachItem?.subMenu && (
                                                    <IoMdArrowDropdown />
                                                  )}
                                                  {eachItem?.subMenu?.length >
                                                    0 &&
                                                    Array.isArray(
                                                      eachItem?.subMenu
                                                    ) && (
                                                      <div
                                                        className={`${classNames.dropdownContent} ${classNames.subDropdown}`}
                                                      >
                                                        {eachItem?.subMenu?.map(
                                                          (
                                                            eachItemSub,
                                                            index
                                                          ) => {
                                                            return (
                                                              <div
                                                                className={
                                                                  classNames.eachFilterItem
                                                                }
                                                                key={
                                                                  eachItemSub?.title +
                                                                  index
                                                                }
                                                                style={{
                                                                  pointerEvents:
                                                                    eachItemSub?.clickable
                                                                      ? ""
                                                                      : "none",
                                                                  opacity:
                                                                    eachItemSub?.clickable
                                                                      ? ""
                                                                      : "0.5",
                                                                  justifyContent:
                                                                    "flex-start",
                                                                  gap: "10px",
                                                                }}
                                                                onClick={() => {
                                                                  history.push(
                                                                    eachItemSub?.link
                                                                  );
                                                                }}
                                                              >
                                                                <span>
                                                                  {eachItemSub.icon && (
                                                                    <eachItemSub.icon />
                                                                  )}
                                                                </span>
                                                                {
                                                                  eachItemSub?.title
                                                                }{" "}
                                                                {eachItemSub?.subMenu && (
                                                                  <IoMdArrowDropdown />
                                                                )}
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                      </div>
                                                    )}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      ))
                                    )}
                                  </div>

                                  <div className={classNames.singleFields}>
                                    {eachItem?.user_first_name
                                      ? eachItem?.user_first_name
                                      : ""}
                                  </div>
                                  <div className={classNames.singleFields}>
                                    {eachItem?.user_email_id
                                      ? eachItem?.user_email_id
                                      : ""}
                                  </div>
                                  <div className={classNames.singleFields}>
                                    {eachItem?.user_phone_number[0]
                                      ? eachItem?.user_phone_number[0]
                                      : ""}
                                  </div>
                                  <div className={classNames.singleFields}>
                                    {eachItem?.leads_count
                                      ? eachItem?.leads_count
                                      : 0}
                                  </div>
                                  <div className={classNames.singleFields}>
                                    {eachItem?.user_roles?.length > 0
                                      ? eachItem?.user_roles?.map(
                                          (eachRole, index) => {
                                            return (
                                              <div style={{ padding: "0" }}>
                                                {eachRole?.role_id?.role_type?.toLowerCase() ===
                                                  "service" &&
                                                eachRole?.role_id?.role_designation?.toLowerCase() ===
                                                  "business head" ? (
                                                  <span>Affiliate</span>
                                                ) : (
                                                  <>
                                                    <span>
                                                      {
                                                        eachRole?.role_id
                                                          ?.role_type
                                                      }
                                                    </span>{" "}
                                                    <span>
                                                      {
                                                        eachRole?.role_id
                                                          ?.role_designation
                                                      }
                                                    </span>
                                                  </>
                                                )}
                                              </div>
                                            );
                                          }
                                        )
                                      : "-"}
                                  </div>
                                </div>
                              </>
                            );
                          })
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames.paginationBar}>
              <div className={classNames.paginationMsg}>
                {/* Showing data 1 to 8 of 250 entries */}
                Showing {teamTableData?.length}
                entries
              </div>
            </div>
          </div>
        )}
      </div>
      {popup && popup?.title === "Add Cornerstone Employee" && (
        <AddRahaEmployee setPopup={setPopup} />
      )}
      {popup && popup?.title === "Complete registration" && (
        <CompleteRegistration
          setPopup={setPopup}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          setSelectedMenu={setSelectedMenu}
        />
      )}
      {popup && popup?.title === "Initiate Quote" && (
        <InitiateQuote setPopup={setPopup} selectedRow={selectedRow} />
      )}
      {popup && popup?.title === "Add new policy" && (
        <AddPolicy
          setPopup={setPopup}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
        />
      )}
      {popup && popup?.title === "Admin reset password" && (
        <AdminResetPassword
          popup={popup}
          setPopup={setPopup}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
        />
      )}
      {popup && popup?.title === "Add new rater card" && (
        <AddNewRaterCard
          popup={popup}
          setPopup={setPopup}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
        />
      )}
      {showInitiateSuccess ? (
        <>
          <div
            className={classNames.overlay1}
            onClick={(e) => {
              setShowInitiateSuccess(false);
            }}
          ></div>
          <div className={classNames.successDialogue}>
            <ColorLogo style={{ width: "209px", height: "69px" }} />
            <div>Your Lead Hierarchy Has Been Activated</div>
          </div>
        </>
      ) : (
        ""
      )}
      {showAssignPopup && (
        <AssignRole
          setShowAssignPopup={setShowAssignPopup}
          showAssignPopup={showAssignPopup}
          getAllLeads={getAllLeads}
        />
      )}
    </div>
  );
};

export default DashboardAssociate;

export const SidebarMenu = ({
  position,
  onClose,
  openedItem,
  selectedItemMain,
}) => {
  const sidebarRef = useRef(null);
  const { popularAction } = useContext(BankContext);

  const [allDependents, setAllDependents] = useState([]);
  const [allDependentsLoading, setAllDependentsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [scrollLocked, setScrollLocked] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose(); // Call the onClose function to change the state
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    setAllDependentsLoading(true);
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/dependent/get?user_email=${openedItem}`
    )
      .then((response) => {
        // console.log(
        //   response?.data?.data,
        //   "get all dependents response",
        //   openedItem
        // );
        setAllDependents(response?.data?.data);
        setAllDependentsLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "Get all dependents error");
        setAllDependentsLoading(false);
      });
  }, [popularAction, openedItem]);

  return (
    <div
      className={classNames.sidebarMenu}
      style={{
        right: position ? "0" : "",
      }}
      ref={sidebarRef}
    >
      <div className={classNames.dependentsContainer}>
        <div className={classNames.title}>
          Dependents For {selectedItemMain?.first_name}
        </div>
        <div className={classNames.allDependents}>
          {allDependentsLoading ? (
            <div className={classNames.eachDependent}>
              <div>
                <Skeleton height={25} width={80} />
              </div>
              <div>
                <Skeleton height={25} width={80} />
              </div>
              <div>
                <Skeleton height={25} width={80} />
              </div>
            </div>
          ) : allDependents?.length > 0 ? (
            allDependents?.map((eachItem, index) => {
              return (
                <div
                  className={classNames.eachDependent}
                  key={eachItem?.dependent_name + index}
                >
                  <div>{eachItem?.dependent_name}</div>
                  <div>{eachItem?.relationship}</div>
                  <div>{eachItem?.primary_phone_number}</div>
                </div>
              );
            })
          ) : (
            <div>No items</div>
          )}
        </div>
      </div>
    </div>
  );
};

const CustomDropdown = ({
  width,
  color,
  buttonText,
  dropdownItems,
  stateValue,
  setState,
  typee,
}) => {
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={classNames.customDropdown}
      style={{
        width: width ? width : "",
        color: color ? color : "",
        overflow: isActive ? "" : "hidden",
      }}
      ref={dropdownRef}
    >
      <div
        className={classNames.dropdownBtn}
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <div>{stateValue ? stateValue : buttonText}</div>
        <IoMdArrowDropdown
          style={{
            fontSize: "1.2rem",
            rotate: isActive ? "180deg" : "",
          }}
        />
      </div>

      <div
        className={classNames.dropdownContent}
        style={{
          display: isActive ? "" : "none",
          zIndex: isActive ? "7" : "",
          height: "max-content",
        }}
      >
        {dropdownItems?.length > 0 && typee == "v3"
          ? dropdownItems?.map((eachItem, index) => {
              return (
                <div
                  className={classNames.eachContent1}
                  key={eachItem?.title + index}
                  onClick={() => {
                    if (eachItem?.clickable) {
                      setState(eachItem?.title);
                    }
                    setIsActive(false);
                  }}
                  style={{
                    cursor: eachItem?.clickable ? "" : "not-allowed",
                    background: eachItem?.color ? eachItem?.color : "",
                    margin: eachItem?.color ? "10px" : "",
                    height: eachItem?.color ? "29px" : "",
                    color: eachItem?.color ? "white" : "",
                    fontWeight: eachItem?.color ? "bold" : "",
                    display: eachItem?.color ? "flex" : "",
                    justifyContent: eachItem?.color ? "center" : "",
                    alignItems: eachItem?.color ? "center" : "",
                    borderRadius: "5px",
                  }}
                >
                  {eachItem?.title}
                </div>
              );
            })
          : dropdownItems?.length > 0 &&
            dropdownItems?.map((eachItem, index) => {
              return (
                <div
                  className={classNames.eachContent}
                  key={eachItem + index}
                  onClick={() => {
                    setState(eachItem);
                    setIsActive(false);
                  }}
                >
                  {eachItem}
                </div>
              );
            })}
      </div>
    </div>
  );
};

const RemoveRole = ({ mapItems, setState, setLocalRefresh, finalFunc }) => {
  async function removeRole(roleID) {
    try {
      let response = await Axios.delete(
        `https://coverfi.apimachine.com/api/v1/raha-user/role/mapping/delete/${roleID}`
      );
      if (response?.data?.status) {
        predefinedToast(response?.data?.message);
        setState(null);
        setLocalRefresh((prev) => !prev);
        finalFunc();
        console.log(response, "Removed role response");
      }
    } catch (error) {
      console.log(error?.message, "Remove role error");
    }
  }
  return (
    <div className={classNames.removeRole}>
      <div className={classNames.title}>Which role do you want to remove?</div>
      {Array.isArray(mapItems) && mapItems?.length > 0
        ? mapItems?.map((eachRole, index) => {
            return (
              <div
                style={{ padding: "0.7rem 0.5rem" }}
                onClick={() => removeRole(eachRole?._id)}
              >
                {eachRole?.role_id?.role_type?.toLowerCase() === "service" &&
                eachRole?.role_id?.role_designation?.toLowerCase() ===
                  "business head" ? (
                  <span>Affiliate</span>
                ) : (
                  <>
                    <span>{eachRole?.role_id?.role_type}</span>{" "}
                    <span>{eachRole?.role_id?.role_designation}</span>
                  </>
                )}
              </div>
            );
          })
        : ""}
    </div>
  );
};

const AddRole = ({
  mapItems,
  setState,
  setLocalRefresh,
  finalFunc,
  selectedRow,
}) => {
  const [loading, setLoading] = useState(false);
  async function addRole(roleID) {
    // console.log(roleID, selectedRow?._id, "Add role object", getCurrentDate());
    setLoading(true);
    try {
      let response = await Axios.post(
        `https://coverfi.apimachine.com/api/v1/raha-user/role/mapping/create`,
        {
          role_id: roleID,
          user_id: selectedRow?._id,
          service_start_date: getCurrentDate(),
        }
      );
      if (response?.data?.status) {
        predefinedToast(response?.data?.message);
        setState(null);
        setLocalRefresh((prev) => !prev);
        finalFunc();
        console.log(response, "Added role response");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      predefinedToast(error?.message);
      console.log(error?.message, "Add role error");
    }
  }
  return (
    <div className={classNames.removeRole}>
      <div className={classNames.title}>Which role do you want to add?</div>
      {Array.isArray(mapItems) && mapItems?.length > 0
        ? mapItems?.map((eachRole, index) => {
            return (
              <div
                style={{ padding: "0.7rem 0.5rem" }}
                onClick={() => addRole(eachRole?._id)}
              >
                {eachRole?.role_type?.toLowerCase() === "service" &&
                eachRole?.role_designation?.toLowerCase() ===
                  "business head" ? (
                  <span>Affiliate</span>
                ) : (
                  <>
                    <span>{eachRole?.role_type}</span>{" "}
                    <span>{eachRole?.role_designation}</span>
                  </>
                )}
              </div>
            );
          })
        : ""}
      {loading && (
        <LoadingAnimationRahaLogo
          height={"100%"}
          position="absolute"
          opacity="0.5"
          inset="0"
        />
      )}
    </div>
  );
};
