import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./myprofile.module.scss";
import { BankContext } from "../../context/Context";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import {
  CopyBtn,
  EachCustomPolicyNumberDropdown,
  EachCustomProfileDropdown,
} from "../../components/Custom";

import { ReactComponent as CompleteIcon } from "../../assest/images/icons/complete.svg";
import { ReactComponent as TrashcanIcon } from "../../assest/images/icons/trashcan.svg";

//assets icon
import { ReactComponent as PropertyIcon } from "../../assest/images/profile/property.svg";
import { ReactComponent as VehicleIcon } from "../../assest/images/profile/vehicle.svg";
import { ReactComponent as WorldIcon } from "../../assest/images/profile/world.svg";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NavbarV2 from "../../components/NavbarV2";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import {
  EachCustomDropdown,
  EachCustomInput,
  EachCustomSelectOne,
} from "../../components/Custom/Input";
import NavbarV3 from "../../components/NavbarV3";
import { logoutToSameLogin } from "../../assest/functions";

const MyProfile = () => {
  const history = useHistory();
  const {
    name,
    email,
    updateLoading,
    setUpdateLoading,
    globalSelectedPolicy,
    globalUserEmployeeDetails,
  } = useContext(BankContext);
  const [selectedProfileNav, setSelectedProfileNav] = useState(
    "My Employee Profile"
  );
  const [selectedDiffSection, setSelectedDiffSection] =
    useState("Personal Details");
  const [mobileAdding, setMobileAdding] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [isProfileFound, setIsProfileFound] = useState(false);
  const [newAdministratorForm, setNewAdministratorForm] = useState("");

  useEffect(() => {
    setUpdateLoading(true);
    Axios.get(
      `https://insurancecoverfi.apimachine.com/insurance/user/get?user_email=${email}`
    )
      .then((response) => {
        // console.log(response, "user profile response");
        if (response?.data?.status && response?.data?.data?.length > 0) {
          setIsProfileFound(response?.data?.data[0]);
        } else {
          setIsProfileFound(false);
        }
        setUpdateLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, "user profile error");
        setUpdateLoading(false);
      });
  }, []);

  let isProfileFromEmployee =
    globalUserEmployeeDetails?.length > 0
      ? globalUserEmployeeDetails?.filter(
          (eachItem) => eachItem?.employee?.toLowerCase() == "yes"
        )
      : [];

  async function downloadHealthCard() {
    setUpdateLoading(true);
    let isProfileFromEmployee =
      (await globalUserEmployeeDetails?.length) > 0
        ? globalUserEmployeeDetails?.filter(
            (eachItem) => eachItem?.employee?.toLowerCase() == "yes"
          )
        : [];
    console.log(
      isProfileFromEmployee,
      "isProfileFromEmployee downloadHealthcard"
    );
    Axios.get(
      `https://comms.globalxchange.io/retired/app/ecardurl?employeeId=${
        isProfileFromEmployee?.length > 0 &&
        isProfileFromEmployee[0]?.user_employee_id
          ? isProfileFromEmployee[0]?.user_employee_id
          : ""
      }&policyNumber=${globalSelectedPolicy}`
    )
      .then((response) => {
        // console.log(response, "downloadHealthCard response");
        setUpdateLoading(false);
        if (response?.data?.status && response?.data?.data?.ecardUrl) {
          window?.open(response?.data?.data?.ecardUrl, "_blank");
        }
      })
      .catch((error) => {
        console.log(error?.message, "downloadHealthCard error");
        setUpdateLoading(false);
      });
  }

  if (!email) {
    logoutToSameLogin(history);
  }

  return (
    <div className={classNames.profile}>
      {updateLoading ? (
        ""
      ) : isProfileFound ? (
        <>
          <NavbarV3
            dropdownItems={[]}
            selectedTab={selectedProfileNav}
            setSelectedTab={setSelectedProfileNav}
            setSearchQuery={setSearchQuery}
          />
          <div className={classNames.myProfileDetails}>
            <div className={classNames.mainDiv}>
              <div className={classNames.detailedItem}>
                <div>
                  {/* <img
                    src={
                      isProfileFound?.profile_picture
                        ? isProfileFound?.profile_picture
                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl7Cadho1YF1TCFZRfanGSwIxnklacJPtiycrPEgtw&s"
                    }
                    alt="brokerage_icon"
                  /> */}
                  <div
                    style={{
                      background: "var(--main-color)",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      width: "100%",
                      height: "100%",
                      fontSize: "1.2rem",
                    }}
                  >
                    {isProfileFromEmployee?.length > 0 &&
                    isProfileFromEmployee[0]?.first_name
                      ? isProfileFromEmployee[0]?.first_name?.charAt(0)
                      : ""}
                  </div>
                </div>
                <div>
                  <div>
                    {isProfileFromEmployee?.length > 0 &&
                    isProfileFromEmployee[0]?.first_name &&
                    isProfileFromEmployee[0]?.last_name
                      ? isProfileFromEmployee[0]?.first_name +
                        isProfileFromEmployee[0]?.last_name
                      : isProfileFromEmployee?.length > 0 &&
                        isProfileFromEmployee[0]?.first_name
                      ? isProfileFromEmployee[0]?.first_name
                      : ""}
                  </div>
                  <div onClick={downloadHealthCard}>Download Health Card</div>
                </div>
              </div>
              <div className={classNames.diffSections}>
                <div
                  className={
                    selectedDiffSection === "Personal Details"
                      ? classNames.selectedSection
                      : ""
                  }
                  onClick={() => {
                    setSelectedDiffSection("Personal Details");
                  }}
                >
                  Personal Details
                </div>
                <div
                  className={
                    selectedDiffSection === "Company Details"
                      ? classNames.selectedSection
                      : ""
                  }
                  onClick={() => {
                    setSelectedDiffSection("Company Details");
                  }}
                >
                  Company Details
                </div>
                <div
                  className={
                    selectedDiffSection === "Admin Management"
                      ? classNames.selectedSection
                      : ""
                  }
                  onClick={() => {
                    setSelectedDiffSection("Admin Management");
                  }}
                >
                  Admin Management
                </div>
              </div>
            </div>
            <div className={classNames.selectedSectionMain}>
              {selectedDiffSection === "Personal Details" ? (
                <div className={classNames.personalDetails}>
                  <EachProfileInput
                    title="Full Name"
                    placeholder="Enter name..."
                    dropdown=""
                    stateValue={
                      isProfileFromEmployee?.length > 0 &&
                      isProfileFromEmployee[0]?.first_name &&
                      isProfileFromEmployee[0]?.last_name
                        ? isProfileFromEmployee[0]?.first_name +
                          isProfileFromEmployee[0]?.last_name
                        : isProfileFromEmployee?.length > 0 &&
                          isProfileFromEmployee[0]?.first_name
                        ? isProfileFromEmployee[0]?.first_name
                        : ""
                    }
                  />
                  <EachProfileInput
                    title="Mobile Number"
                    placeholder=""
                    dropdown=""
                    // stateValue={
                    //   isProfileFound?.primary_phone_number
                    //     ? isProfileFound?.primary_phone_number
                    //     : ""
                    // }
                    stateValue={
                      isProfileFromEmployee?.length > 0 &&
                      isProfileFromEmployee[0]?.phone_number
                        ? isProfileFromEmployee[0]?.phone_number
                        : ""
                    }
                  />
                  {/* <EachProfileInput
                    title="Last Name"
                    placeholder="Enter last..."
                    dropdown=""
                    stateValue={
                      isProfileFound?.last_name ? isProfileFound?.last_name : ""
                    }
                  /> */}
                  <EachProfileInput
                    title="Email"
                    placeholder="Enter email..."
                    dropdown=""
                    // stateValue={
                    //   isProfileFound?.user_email
                    //     ? isProfileFound?.user_email
                    //     : ""
                    // }
                    stateValue={
                      isProfileFromEmployee?.length > 0 &&
                      isProfileFromEmployee[0]?.lead_email
                        ? isProfileFromEmployee[0]?.lead_email
                        : ""
                    }
                  />
                  {/* <EachProfileInput
                    title="Date Of Birth (DD/MM/YYYY)"
                    placeholder="Enter DOB..."
                    dropdown=""
                    stateValue={
                      isProfileFound?.date_of_birth
                        ? isProfileFound?.date_of_birth
                        : ""
                    }
                  /> */}

                  {/* <EachProfileInput
                    title="Annual Income"
                    placeholder="Select annual income..."
                    dropdown="true"
                    stateValue="Less then 1Lahk"
                  /> */}
                  {/* <EachProfileInput
                    title="Martial Status"
                    placeholder="Select marriage status..."
                    dropdown="true"
                    stateValue={
                      isProfileFound?.martial_status
                        ? isProfileFound?.martial_status
                        : ""
                    }
                  />
                  <EachProfileInput
                    title="Address"
                    placeholder="Enter Address..."
                    dropdown=""
                    stateValue={
                      isProfileFound?.address ? isProfileFound?.address : ""
                    }
                  />
                  <EachProfileInput
                    title="City"
                    placeholder="Enter city..."
                    dropdown=""
                    stateValue={
                      isProfileFound?.city ? isProfileFound?.city : ""
                    }
                  />
                  <EachProfileInput
                    title="Country"
                    placeholder="Enter Country..."
                    dropdown=""
                    stateValue={
                      isProfileFound?.country ? isProfileFound?.country : ""
                    }
                  />
                  <EachProfileInput
                    title="Postal Code"
                    placeholder="Enter pincode..."
                    dropdown=""
                    stateValue={
                      isProfileFound?.pin_code ? isProfileFound?.pin_code : ""
                    }
                  /> */}
                </div>
              ) : selectedDiffSection === "Company Details" ? (
                <div className={classNames.personalDetails}>
                  <EachProfileInput
                    title="Company Name"
                    placeholder=""
                    dropdown=""
                    stateValue={
                      isProfileFromEmployee?.length > 0 &&
                      isProfileFromEmployee[0]?.employee_company_name
                        ? isProfileFromEmployee[0]?.employee_company_name
                        : ""
                    }
                  />
                  <EachProfileInput
                    title="Employee ID"
                    placeholder=""
                    dropdown=""
                    stateValue={
                      isProfileFromEmployee?.length > 0 &&
                      isProfileFromEmployee[0]?.user_employee_id
                        ? isProfileFromEmployee[0]?.user_employee_id
                        : ""
                    }
                  />
                </div>
              ) : selectedDiffSection === "Admin Management" ||
                selectedDiffSection === "Admin Management Form" ? (
                <div className={classNames.adminContainer}>
                  {selectedDiffSection === "Admin Management" ? (
                    <div className={classNames.tableContainer}>
                      <table className={classNames.tableParent}>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Sr. No.</th>
                            <th>Admin Name</th>
                            <th>Designation</th>
                            <th>Email ID</th>
                            <th>Mobile No.</th>
                            <th>Status</th>
                            <th
                              onClick={() =>
                                setSelectedDiffSection("Admin Management Form")
                              }
                            >
                              +
                            </th>
                          </tr>
                        </thead>
                        <tbody className={classNames.tableBody}>
                          <tr className={classNames.tableRow}>
                            <td>
                              <MdEdit />
                            </td>
                            <td>1</td>
                            <td>Betty Thomas</td>
                            <td>HR Manager</td>
                            <td>betty@starfishgp.com</td>
                            <td>+91 768559222</td>
                            <td>Invited</td>
                            <td>
                              <RiDeleteBin5Line />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className={classNames.adminManagementForm}>
                      <div className={classNames.tableHead}>
                        <div>Add New Adminstrator</div>
                        <div
                          onClick={() =>
                            setSelectedDiffSection("Admin Management")
                          }
                        >
                          X
                        </div>
                      </div>
                      <div className={classNames.formBody}>
                        <div className={classNames.gridFour}>
                          <EachCustomDropdown
                            buttonText={
                              newAdministratorForm ? newAdministratorForm : "Mr"
                            }
                            // color="var(--main-color)"
                            dropdownItems={[]}
                            name="Mr"
                            placeholder="Mr"
                            stateValue={newAdministratorForm}
                            setState={setNewAdministratorForm}
                            typee="obj"
                          />
                          <EachCustomInput
                            placeholder="First name"
                            name="firstName"
                            stateValue={newAdministratorForm}
                            setState={setNewAdministratorForm}
                          />
                          <EachCustomInput
                            placeholder="Last name"
                            name="lastName"
                            stateValue={newAdministratorForm}
                            setState={setNewAdministratorForm}
                          />
                          <EachCustomInput
                            placeholder="Designation"
                            name="designation"
                            stateValue={newAdministratorForm}
                            setState={setNewAdministratorForm}
                          />
                        </div>
                        <div className={classNames.gridFour}>
                          <EachCustomDropdown
                            buttonText={
                              newAdministratorForm
                                ? newAdministratorForm
                                : "+91"
                            }
                            // color="var(--main-color)"
                            dropdownItems={[]}
                            name="+91"
                            placeholder="+91"
                            stateValue={newAdministratorForm}
                            setState={setNewAdministratorForm}
                            typee="obj"
                          />
                          <EachCustomInput
                            placeholder="Phone number"
                            name="phoneNumber"
                            stateValue={newAdministratorForm}
                            setState={setNewAdministratorForm}
                          />
                          <EachCustomInput
                            placeholder="Email ID"
                            name="email"
                            stateValue={newAdministratorForm}
                            setState={setNewAdministratorForm}
                          />
                        </div>
                        <EachCustomSelectOne
                          title="Are they already a Cornerstone user?"
                          placeholder="Designation"
                          name="designation"
                          stateValue={newAdministratorForm}
                          setState={setNewAdministratorForm}
                          dropdown={["Yes", "No"]}
                        />
                        <div className={classNames.greenbtn}>Invite Admin</div>
                      </div>
                    </div>
                  )}
                </div>
              ) : selectedDiffSection === "Contact Details" ? (
                <div className={classNames.contactDetails}>
                  <div>
                    <div className={classNames.title}>Mobile Numbers</div>
                    <EachProfileInput
                      title="Mobile Number"
                      placeholder="Enter number..."
                      dropdown=""
                      stateValue={
                        isProfileFound?.primary_phone_number
                          ? isProfileFound?.primary_phone_number
                          : ""
                      }
                      status="primary"
                    />
                    {/* {Array.from({ length: mobileAdding }).map((_, index) => (
                      <EachProfileInput
                        title="Mobile Number"
                        placeholder="Enter number..."
                        dropdown=""
                        stateValue="+91 52351 12345"
                        status="secondary"
                        removeState={setMobileAdding}
                      />
                    ))} */}
                    {isProfileFound?.secondary_phone_numbers
                      ? isProfileFound?.secondary_phone_numbers?.map(
                          (number, index) => (
                            <EachProfileInput
                              title="Mobile Number"
                              placeholder="Enter number..."
                              dropdown=""
                              stateValue={number ? number : ""}
                              status="secondary"
                              removeState={setMobileAdding}
                            />
                          )
                        )
                      : ""}
                    <div
                      className={classNames.addNewBtn}
                      onClick={() => {
                        setMobileAdding((prev) => prev + 1);
                      }}
                    >
                      <div className={classNames.icon}>+</div>
                      <span>Add new number</span>
                    </div>
                  </div>
                  <div>
                    <div className={classNames.title}>Email Addresses</div>
                    <EachProfileInput
                      title="Email Address"
                      placeholder="Enter email..."
                      dropdown=""
                      stateValue={
                        isProfileFound?.user_email
                          ? isProfileFound?.user_email
                          : ""
                      }
                      status="primary"
                    />
                    {/* {Array.from({ length: emailAdding }).map((_, index) => (
                      <EachProfileInput
                        title="Email Address"
                        placeholder="Enter email..."
                        dropdown=""
                        stateValue="shorupan@gmail.com"
                        status="secondary"
                        removeState={setEmailAdding}
                      />
                    ))} */}
                    {/* <div
                      className={classNames.addNewBtn}
                      onClick={() => {
                        setEmailAdding((prev) => prev + 1);
                      }}
                    >
                      <div className={classNames.icon}>+</div>
                      <span>Add new email</span>
                    </div> */}
                  </div>
                </div>
              ) : (
                <div className={classNames.assetsDetails}>
                  <div className={classNames.title}>Add</div>
                  <div className={classNames.assetsContainer}>
                    <div className={classNames.eachAsset}>
                      <div>Property</div>
                      <div>
                        <PropertyIcon />
                        <div className={classNames.shadeArea}></div>
                      </div>
                    </div>
                    <div className={classNames.eachAsset}>
                      <div>Vehicles</div>
                      <div>
                        <VehicleIcon />
                        <div className={classNames.shadeArea}></div>
                      </div>
                    </div>
                    <div className={classNames.eachAsset}>
                      <div>Other</div>
                      <div>
                        <WorldIcon />
                        <div className={classNames.shadeArea}></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={classNames.noProfile}>
          <div>
            <div>Looks Like You Haven’t Completed</div>
            <div>Updating Your Cornerstone Profile</div>
          </div>
          <div>No Problem. It Only Takes A Few Minutes.</div>
          <Link to="/app/coverfi/newprofile">Complete Registration</Link>
        </div>
      )}
    </div>
  );
};

export default MyProfile;

const EachProfileInput = ({
  title,
  placeholder,
  dropdown,
  status,
  stateValue,
  setState,
  removeState,
}) => {
  return (
    <div className={classNames.eachProfileInput}>
      <div className={classNames.title}>{title}</div>
      <div className={classNames.inputParent}>
        <input
          type="text"
          name=""
          placeholder={placeholder}
          value={stateValue}
          className={classNames.inputContainer}
          style={{ pointerEvents: setState ? "" : "none" }}
        />
        {status == "secondary" ? (
          <div
            className={classNames.trashCan}
            onClick={() => {
              removeState((prev) => prev - 1);
            }}
          >
            <TrashcanIcon />
          </div>
        ) : (
          ""
        )}
        {status == "primary" ? (
          <div className={classNames.status}>
            <CompleteIcon />
            <span>Primary</span>
          </div>
        ) : (
          ""
        )}
      </div>
      {dropdown && <IoMdArrowDropdown />}
    </div>
  );
};
