import React from "react";
import classNames from "./navbar.module.scss";

import { useState } from "react";
import { EachCustomPolicyNumberDropdown, HRIDDropdown } from "../Custom";
import { ReactComponent as NotificationIcon } from "../../assest/images/icons/notification.svg";
import { useContext } from "react";
import { BankContext } from "../../context/Context";
import cartIcon from "../../assest/images/icons/cart.svg";
import { useRef } from "react";
import { useEffect } from "react";
import { IoMdArrowDropdown } from "react-icons/io";

import { ReactComponent as ProfileIcon } from "../../assest/images/allicons/profile.svg";
import { ReactComponent as FeedbackIcon } from "../../assest/images/allicons/feedback.svg";
import { Link } from "react-router-dom";

import { BsDot } from "react-icons/bs";
import { MdKeyboardArrowUp } from "react-icons/md";

import tempNotoficationImage from "./tempnotificationimage.svg";
import headspaceIcon from "./headspace.svg";
import rahaIcon from "./raha.svg";
import { RiSettingsFill, RiCloseFill } from "react-icons/ri";
import { capitalizeFirstLetter } from "../../utils/FunctionTools";

const NavbarV3 = ({
  dropdownItems,
  selectedTab,
  setSelectedTab,
  setSearchQuery,
  pageSpecificFunc,
  typee,
  removeItem,
  specificPolicies,
}) => {
  const {
    name,
    profileImg,
    allCartItems,
    dashboardToggle,
    globalCompanyProfile,
    usersDataGlobal,
    popupProfileDetails,
    setPopupProfileDetails,
    onLoginFormEmployerProfile,
    profileDetailsAssociatesClone,
  } = useContext(BankContext);

  const [isActive, setIsActive] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const dropdownProfileRef = useRef(null);
  const notificationRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        dropdownProfileRef.current &&
        !dropdownProfileRef.current.contains(event.target)
      ) {
        setIsActive(false);
      }
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setOpenNotification(false);
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const dropdownItemsProfile = [
    {
      title: "My Profile",
      icon: ProfileIcon,
      // route: "/app/coverfi/myprofile"
      route: "/app/agency/myprofile",
    },
    // {
    //   title: "Feedback",
    //   icon: FeedbackIcon,
    //   route: "/app/coverfi/feedbackform",
    // },
  ];

  return (
    <div className={classNames.navbarv3}>
      {dropdownItems?.length > 1 ? (
        <CustomDropdown
          buttonText={selectedTab ? selectedTab : "Dashboard"}
          // color="var(--main-color)"
          dropdownItems={dropdownItems}
          stateValue={selectedTab}
          setState={setSelectedTab}
          typee={typee}
        />
      ) : (
        <div style={{ visibility: "hidden" }}></div>
      )}
      {removeItem &&
      (window?.location?.pathname?.includes("agency") ||
        window?.location?.pathname?.includes("teamlead") ||
        window?.location?.pathname?.includes("verticalhead") ||
        window?.location?.pathname?.includes("businesshead") ||
        window?.location?.pathname?.includes("affiliates")) &&
      (window?.location?.pathname?.includes("hospitals") ||
        window?.location?.pathname?.includes("claims")) ? (
        <div
          className={classNames.associateDropdown}
          style={{
            marginRight: window?.location?.pathname?.includes("claims")
              ? "auto"
              : "",
            marginLeft: window?.location?.pathname?.includes("claims")
              ? "0"
              : "",
          }}
        >
          <HRIDDropdown />
          <EachCustomPolicyNumberDropdown
            specificPolicies={specificPolicies}
            noposition="true"
          />
        </div>
      ) : window?.location?.pathname?.includes("hospitals") ||
        window?.location?.pathname?.includes("claims") ? (
        <EachCustomPolicyNumberDropdown specificPolicies={specificPolicies} />
      ) : (
        ""
      )}
      <div
        className={classNames.mergedProfile}
        style={{ zIndex: popupProfileDetails ? 9 : "" }}
      >
        <div className={classNames.profileDiv} ref={dropdownProfileRef}>
          <div onClick={() => setIsActive(!isActive)}>
            {(window?.location?.pathname?.includes("/agency") ||
              window?.location?.pathname?.includes("/teamlead") ||
              window?.location?.pathname?.includes("/verticalhead") ||
              window?.location?.pathname?.includes("/businesshead") ||
              window?.location?.pathname?.includes("/affiliates")) &&
            profileDetailsAssociatesClone?.user_picture ? (
              <img
                src={profileDetailsAssociatesClone?.user_picture}
                alt="profilePic"
              />
            ) : window?.location?.pathname?.includes("/coverfi") &&
              dashboardToggle == "Employer" &&
              onLoginFormEmployerProfile?.hrDetails?.profile_image ? (
              <img
                src={onLoginFormEmployerProfile?.hrDetails?.profile_image}
                alt="profilePic"
              />
            ) : dashboardToggle == "Employer" &&
              onLoginFormEmployerProfile?.hrDetails?.profile_image ? (
              <img
                src={onLoginFormEmployerProfile?.hrDetails?.profile_image}
                alt="profilePic"
              />
            ) : (
              <div className={classNames.profileLetter}>
                {window?.location?.pathname?.includes("/agency") ||
                window?.location?.pathname?.includes("/teamlead") ||
                window?.location?.pathname?.includes("/verticalhead") ||
                window?.location?.pathname?.includes("/businesshead") ||
                window?.location?.pathname?.includes("/affiliates")
                  ? profileDetailsAssociatesClone?.user_first_name?.charAt(0)
                  : window?.location?.pathname?.includes("/coverfi")
                  ? onLoginFormEmployerProfile?.hrDetails?.first_name?.charAt(0)
                  : usersDataGlobal?.userDetails?.first_name?.charAt(0)}
              </div>
            )}
            {window?.location?.pathname?.includes("/agency") ||
            window?.location?.pathname?.includes("/teamlead") ||
            window?.location?.pathname?.includes("/verticalhead") ||
            window?.location?.pathname?.includes("/businesshead") ||
            window?.location?.pathname?.includes("/affiliates") ? (
              <div className={classNames.nameDetails}>
                <div>
                  {`${
                    profileDetailsAssociatesClone?.user_first_name
                      ? capitalizeFirstLetter(
                          profileDetailsAssociatesClone?.user_first_name
                        )
                      : ""
                  } ${
                    profileDetailsAssociatesClone?.user_last_name
                      ? profileDetailsAssociatesClone?.user_last_name
                      : ""
                  }`}
                </div>
              </div>
            ) : window?.location?.pathname?.includes("/coverfi") ? (
              <div className={classNames.nameDetails}>
                <div>
                  {`${
                    onLoginFormEmployerProfile?.hrDetails?.first_name
                      ? capitalizeFirstLetter(
                          onLoginFormEmployerProfile?.hrDetails?.first_name
                        )
                      : ""
                  } ${
                    onLoginFormEmployerProfile?.leadDetails?.last_name
                      ? onLoginFormEmployerProfile?.leadDetails?.last_name
                      : ""
                  }`}
                </div>
                <div>
                  {onLoginFormEmployerProfile?.hrDetails?.hrCompanyDetails
                    ?.company_name
                    ? onLoginFormEmployerProfile?.hrDetails?.hrCompanyDetails
                        ?.company_name
                    : ""}
                </div>
                {/* {dashboardToggle == "Employer"
                ? globalCompanyProfile?.company_name
                : dashboardToggle == "Employee"
                ? name
                : "-"} */}
              </div>
            ) : (
              <div className={classNames.nameDetails}>
                <div>
                  {`${
                    usersDataGlobal?.userDetails?.first_name
                      ? capitalizeFirstLetter(
                          usersDataGlobal?.userDetails?.first_name
                        )
                      : ""
                  } ${
                    usersDataGlobal?.userDetails?.last_name
                      ? usersDataGlobal?.userDetails?.last_name
                      : ""
                  }`}
                </div>
                <div>
                  {usersDataGlobal?.hrDetails?.hrCompanyDetails?.company_name
                    ? usersDataGlobal?.hrDetails?.hrCompanyDetails?.company_name
                    : ""}
                </div>
                {/* {dashboardToggle == "Employer"
                ? globalCompanyProfile?.company_name
                : dashboardToggle == "Employee"
                ? name
                : "-"} */}
              </div>
            )}
          </div>
          {/* {isActive && (
            <div
              className={classNames.dropdownContent}
              // style={{ top: isActive ? "" : "-800px" }}
            >
              {dropdownItemsProfile?.length > 0 &&
                dropdownItemsProfile?.map((eachItem, index) => {
                  return (
                    <Link
                      className={classNames.eachContent}
                      key={eachItem?.title + index}
                      onClick={() => {
                        // setState(eachItem);
                        setIsActive(false);
                      }}
                      to={`${
                        dashboardToggle == "Employer" &&
                        eachItem?.title == "My Profile"
                          ? "/app/coverfi/companyprofile"
                          : eachItem?.route
                      }`}
                    >
                      {eachItem?.icon && <eachItem.icon />}
                      {eachItem?.title}
                    </Link>
                  );
                })}
            </div>
          )} */}
        </div>
        <div
          className={classNames.notificationIcon}
          style={{ minWidth: "unset", width: "1.5rem" }}
        >
          {popupProfileDetails ? (
            <RiCloseFill onClick={() => setPopupProfileDetails(false)} />
          ) : (
            <RiSettingsFill onClick={() => setPopupProfileDetails(true)} />
          )}
        </div>
        <div
          className={classNames.notificationIcon}
          ref={notificationRef}
          style={{
            pointerEvents: "none",
            opacity: "0.3",
          }}
        >
          {window?.location?.pathname?.includes("/wellness") ? (
            <div className={classNames.cartIcon}>
              <img
                src={cartIcon}
                alt="cartIcon"
                onClick={() => {
                  pageSpecificFunc((prev) => {
                    if (prev === "allcart") {
                      return false;
                    } else {
                      return "allcart";
                    }
                  });
                }}
              />
              <div
                className={classNames.totalItems}
                style={{ display: allCartItems?.length > 0 ? "" : "none" }}
              >
                {allCartItems?.length ? allCartItems?.length : ""}
              </div>
            </div>
          ) : (
            <NotificationIcon
              onClick={() => {
                setOpenNotification(!openNotification);
              }}
            />
          )}
          {openNotification && (
            <div className={classNames.dropdownContent}>
              <div className={classNames.singleNotification}>
                <div className={classNames.heading}>
                  <div className={classNames.title}>
                    <img src={headspaceIcon} alt="headspaceIcon" />
                    Headspace
                  </div>
                  <div>
                    <BsDot />
                  </div>
                  <div className={classNames.duration}>
                    Now <MdKeyboardArrowUp />
                  </div>
                  <div
                    className={classNames.closeBtn}
                    onClick={() => setOpenNotification(false)}
                  >
                    X
                  </div>
                </div>
                <div className={classNames.notificationTitle}>
                  New Guide to Meditation Video Available!
                </div>
                <div className={classNames.notificationMessage}>
                  Exploreour latest meditation video guide on Headspace. Whether
                  you're a seasoned meditator or just starting out, this video
                  offers valuable insights and techniques to help you find inner
                  peace and mindfulness. Check it out now and enhance your
                  meditation journey with Headspace!
                </div>
                <img
                  src={tempNotoficationImage}
                  alt=""
                  className={classNames.notificationImage}
                />
                <div className={classNames.notificationBtn}>
                  Share with Employees
                </div>
              </div>
              <div className={classNames.singleNotification}>
                <div className={classNames.heading}>
                  <div className={classNames.title}>
                    <img src={rahaIcon} alt="rahaIcon" />
                    Cornerstone
                  </div>
                  <div>
                    <BsDot />
                  </div>
                  <div className={classNames.duration}>
                    2 Days Ago <MdKeyboardArrowUp />
                  </div>
                  <div className={classNames.closeBtn}>X</div>
                </div>
                <div className={classNames.notificationTitle}>
                  New Endorsement Created
                </div>
                <div className={classNames.notificationMessage}>
                  You have successfully created endorsement on 02/09/2024 for 10
                  new Employees joining the company and 4 employees leaving.
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavbarV3;

const CustomDropdown = ({
  width,
  color,
  buttonText,
  dropdownItems,
  stateValue,
  setState,
  typee,
}) => {
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={classNames.customDropdown}
      style={{ width: width ? width : "", color: color ? color : "" }}
      ref={dropdownRef}
    >
      <div
        className={classNames.dropdownBtn}
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <div>{stateValue ? stateValue : buttonText}</div>
        <IoMdArrowDropdown
          style={{
            fontSize: "1.2rem",
            rotate: isActive ? "180deg" : "",
          }}
        />
      </div>

      <div
        className={classNames.dropdownContent}
        style={{ top: isActive ? "" : "-700px" }}
      >
        {dropdownItems?.length > 0 && typee == "v3"
          ? dropdownItems?.map((eachItem, index) => {
              return (
                <div
                  className={classNames.eachContent}
                  key={eachItem?.title + index}
                  onClick={() => {
                    if (eachItem?.clickable) {
                      setState(eachItem?.title);
                    }
                    setIsActive(false);
                  }}
                  style={{
                    cursor: eachItem?.clickable ? "" : "not-allowed",
                  }}
                >
                  {eachItem?.title}
                </div>
              );
            })
          : dropdownItems?.length > 0 &&
            dropdownItems?.map((eachItem, index) => {
              return (
                <div
                  className={classNames.eachContent}
                  key={eachItem + index}
                  onClick={() => {
                    setState(eachItem);
                    setIsActive(false);
                  }}
                >
                  {eachItem}
                </div>
              );
            })}
      </div>

      <div
        className={classNames.overflowContainer}
        style={{ display: isActive ? "" : "none" }}
        onClick={() => setIsActive(false)}
      ></div>
    </div>
  );
};
